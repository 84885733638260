import React, { useState, Fragment, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;

    background: #fff;
    box-shadow: 0 0 2px grey;
    margin-bottom: 15px;

    .Stepper-NavBar {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding: 5px 10px 5px 10px;
    }

    .Stepper-StepContainer {
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 0px 20px 0px 0;
        cursor: pointer;
    }

    .Stepper-Spacer {
        height: 1px;
        background-color: #c8c8c8;
        flex-grow: 4;
        margin-right: 20px;
    }

    .Stepper-StepNumber {
        width: 25px;
        height: 25px;
        border-radius: 12.5px;
        background-color: #9e9e9e;
        margin-right: 10px;
        text-align: center;
        color: white;
        font-size: 14px;
        line-height: 25px;
    }
    .SelectedNumber {
        background-color: #1891f6;
    }

    .Stepper-StepName {
        font-size: 14px;
        color: #000f7b;
    }

    .SelectedName {
        font-weight: bold;
    }

    @media (max-width: 768px) {
        .Stepper-Spacer {
            display: none;
        }

        .Stepper-NavBar {
            flex-direction: column;
            align-items: unset;
            margin-left: 20px;
        }

        .Stepper-StepContainer {
            padding: 10px;
        }
    }
`
const configRoute = [
    {
        step: 1,
        url: '/configuration',
        name: 'Define configurations to promote',
    },
    { step: 2, url: '/calculation', name: 'Run Calculations' },
    { step: 3, url: '/dashboard', name: 'Display Ecodesign Data' },
]

function Stepper() {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [SelectedStepIndex, setSelectedStepIndex] = useState(1)

    const handleClick = (currentStep) => {
        navigate(configRoute.find(({ step }) => step === currentStep).url)
    }

    useEffect(() => {
        const currentStep = configRoute.find(({ url }) => url.includes(pathname))
        if (currentStep) setSelectedStepIndex(currentStep.step)
    }, [pathname])

    return (
        <Container>
            <div className='Stepper-NavBar'>
                {configRoute.map(({ step, name }, index) => (
                    <Fragment key={'stepper-' + index}>
                        <div className='Stepper-StepContainer' onClick={() => handleClick(index + 1)}>
                            <div
                                className={
                                    'Stepper-StepNumber' + (SelectedStepIndex === step ? ' SelectedNumber' : '')
                                }>
                                {step}
                            </div>
                            <span className={'Stepper-StepName ' + (step === SelectedStepIndex ? 'SelectedName' : '')}>
                                {name}
                            </span>
                        </div>

                        {index < configRoute.length - 1 && <span className='Stepper-Spacer' />}
                    </Fragment>
                ))}
            </div>
        </Container>
    )
}

export default Stepper
