import React, { useContext, useEffect, useState } from 'react'

import TableDisplayData from './TableDisplayData'
import Grid from '@material-ui/core/Grid'
import { GlobalContext, actionContext } from '../../../store'
import { fetchInitialProduct } from '../../../api'

function TabDisplayEcodesignData() {
    const [state, dispatch] = useContext(GlobalContext)

    useEffect(() => {
        fetchInitialProduct().then((res) => {
            dispatch({
                type: actionContext.FETCH_PRODUCTS_AVAILABLE,
                data: res,
            })
        })
    }, [])

    return (
        <div className='Tab'>
            <Grid justifyContent='center' container spacing={2}>
                {state.products
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((product) => product && <TableDisplayData key={'table-' + product.name} product={product} />)}
            </Grid>
        </div>
    )
}

export default TabDisplayEcodesignData
