export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}

export const sortByName = (a, b) => a.name.localeCompare(b.name)

export const sortByUppercaseName = (a, b) => a.Name.localeCompare(b.Name)

export const sortBySize = (a, b) => {
    if (a[0] === 'Size' || b[0] === 'Size') return -1
}

export const sortOptionDescriptionBySize = (a, b) => {
    if (a.Description === 'Size') return -1
    if (a.Description !== 'Size' && b.Description !== 'Size' && a.Description < b.Description) return -1
}

export const formatCandidateParameters = (candidate) =>
    candidate.Parameters.map(({ Name, Values }) => ({
        Name,
        Value: Values[0].Value,
    })).sort(sortByName)

export const sortParameterBySize = (a, b) => {
    if (
        a.Parameters.find((parameter) => parameter.Name === 'Sel_sSize')?.Values[0].Value <
        b.Parameters.find((parameter) => parameter.Name === 'Sel_sSize')?.Values[0].Value
    )
        return -1
}

export const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}_${month}_${day}`
}
