import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import Header from './Components/Header/Header'
import { GlobalContext, actionContext } from './store/index'
import Router from './router'
import useInitData from './hooks/useInitData'

const Wrap = styled.div`
    max-width: calc(100% - 50px);
    margin: 0 auto 0 auto;
`

function App({ permissions }) {
    const [, dispatch] = useContext(GlobalContext)
    useInitData(permissions)

    return (
        <>
            <Header UserName={permissions.firstName + ' ' + permissions.lastName} />
            <Wrap>
                <Router UserName={permissions.firstName + ' ' + permissions.lastName} />
            </Wrap>
        </>
    )
}

export default App
