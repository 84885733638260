import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const StyledContainerLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
`

const Loader = () => (
    <StyledContainerLoading>
        <CircularProgress disableShrink />
    </StyledContainerLoading>
)

export default Loader
