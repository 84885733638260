import Constants from '../Constants'
import { apiUrl } from '../../Environments/environment'
import qs from 'query-string'

function postAllCalculationSync(body, params) {
    const query = params ? '?' + qs.stringify(params) : ''

    return new Promise((next) => {
        fetch(`${apiUrl}calculations/sync/all/${query}`, {
            method: 'POST',
            headers: Constants.ApiHeader(),
            body: JSON.stringify(body),
        }).then((res) => next(res.json()))
    })
}

function postAllCalculationAsync(body, params) {
    const query = params ? '?' + qs.stringify(params) : ''
    return new Promise((next) => {
        fetch(`${apiUrl}calculations/async/all/${query}`, {
            method: 'POST',
            headers: Constants.ApiHeader(),
            body: JSON.stringify(body),
        })
            .then((res) => next(res.json()))
            .catch((error) => console.error(error))
    })
}

function postCalculationLock(body, model) {
    return new Promise((next) => {
        fetch(`${apiUrl}calculations/locked/${model}`, {
            method: 'POST',
            headers: Constants.ApiHeader(),
            body: JSON.stringify(body),
        }).then((res) => next(res.json()))
    })
}

function getCalculationLock(model) {
    return new Promise((next) => {
        fetch(`${apiUrl}calculations/locked/${model}`, {
            method: 'GET',
            headers: Constants.ApiHeader(),
        }).then((res) => next(res.json()))
    })
}

const postPerformancesData = (body) => {
    return new Promise((next) => {
        fetch(apiUrl + 'ecodesign/addManuelPerformances', {
            method: 'POST',
            headers: Constants.ApiHeader(),
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((json) => next(json))
    })
}

export {
    postAllCalculationAsync,
    postAllCalculationSync,
    postCalculationLock,
    getCalculationLock,
    postPerformancesData,
}
