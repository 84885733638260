import ExcelJS from 'exceljs'
import { formatDate } from '../../../../Assets/Helpers'

const getPerfsFromData = (data) => {
    const perfs = []
    for (const el of data) {
        if (el?.Parameters) {
            for (const parameter of el.Parameters) {
                if (parameter.Type === 'out' && parameter.Values[0].Status[0].CalcType) {
                    const existingPerf = perfs.find((perf) => perf.name === parameter.Name)
                    if (!existingPerf) {
                        perfs.push({ name: parameter.Name, unit: parameter.Unit })
                    }
                }
            }
        }
    }
    return perfs
}

const generateExcelFile = ({ model, calcTypes, data }) => {
    const optionsList = data[0]?.Parameters?.filter((parameter) => parameter.Type == 'in')
        .sort((a, b) => a.Description < b.Description)
        .map((opt) => opt.Description)

    const allPerfs = getPerfsFromData(data)
    const uniquePerfs = Array.from(new Set(allPerfs.map((obj) => obj.name))).map((name) =>
        allPerfs.find((obj) => obj.name === name)
    )
    const perfsList = uniquePerfs.map((perf) => perf.name)
    const unitsList = uniquePerfs.map((perf) => perf.unit)
    const workbook = new ExcelJS.Workbook()
    let sheets = []
    calcTypes.forEach((calculation) => sheets.push(workbook.addWorksheet(calculation)))
    sheets.length > 0 &&
        sheets.forEach((sheet) => {
            const calcType = sheet.name
            const candidateTitle = sheet.addRow(['CANDIDATES DESCRIPTION'])
            sheet.mergeCells(1, 1, 1, optionsList.length)
            if (perfsList.length > 0)
                sheet.mergeCells(1, optionsList.length + 1, 1, optionsList.length + perfsList.length)

            candidateTitle.font = { family: 4, size: 10, bold: true }
            candidateTitle.alignment = { horizontal: 'center' }
            sheet.getCell('A1').fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'fde34b' },
            }
            sheet.getColumn(optionsList.length + 1).values = ['PERFORMANCES']
            sheet.getColumn(optionsList.length + 1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '3f51b5' },
            }
            const optionsRow = sheet.addRow([...optionsList, ...perfsList, ...optionsList.map(() => '')])
            optionsRow.getCell(2).fill = {
                type: 'pattern',
                pattern: 'darkVertical',
                fgColor: { argb: 'fde34b' },
            }
            optionsRow.eachCell((cell, index) => {
                cell.protection = { locked: index <= optionsList.length + unitsList.length ? true : false }
            })
            const unitsRow = sheet.addRow([...optionsList.map(() => ''), ...unitsList, ...optionsList.map(() => '')])

            unitsRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' },
            }
            unitsRow.border = {
                top: { style: 'thin', color: { argb: 'eceff1' } },
                left: { style: 'thin', color: { argb: 'eceff1' } },
                bottom: { style: 'thin', color: { argb: 'eceff1' } },
                right: { style: 'thin', color: { argb: 'eceff1' } },
            }
            unitsRow.eachCell((cell, index) => {
                cell.protection = { locked: index <= optionsList.length + unitsList.length ? true : false }
            })
            optionsRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0ab624' },
            }
            data?.map(({ Parameters }, index) => {
                const optionValues = optionsList.map((option) => {
                    const parameter = Parameters.find((parameter) => parameter.Description == option)
                    const parameterValue = parameter?.Values[0]?.Value
                    return parameterValue
                })
                const perfsValues = perfsList.map((perf) => {
                    const parameter = Parameters.find(
                        (parameter) =>
                            parameter.Name == perf && parameter.Values[0].Status[0].CalcType?.trim() == calcType
                    )
                    const parameterValue = parameter?.Values[0]?.Value || ''
                    return parameterValue
                })
                const row = sheet.addRow([...optionValues, ...perfsValues, ...optionsList.map(() => '')])
                row.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: index % 2 === 1 ? 'f6f8fa' : 'ffffff' },
                }
                row.border = {
                    top: { style: 'thin', color: { argb: 'eceff1' } },
                    left: { style: 'thin', color: { argb: 'eceff1' } },
                    bottom: { style: 'thin', color: { argb: 'eceff1' } },
                    right: { style: 'thin', color: { argb: 'eceff1' } },
                }
                row.eachCell((cell, index) => {
                    cell.protection = { locked: index <= optionValues.length ? true : false }
                })
                sheet.protect('', { selectLockedCells: true, selectUnlockedCells: true })
            })
        })

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        const formattedDate = formatDate(new Date())
        anchor.download = `Ecodesign_[${model}]_[${formattedDate}].xlsx`
        anchor.click()
        window.URL.revokeObjectURL(url)
    })
}

export default generateExcelFile
