import React from 'react'
import styled from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px;
`

const StyledBox = styled.div`
    color: ${({ color }) => color};
`

const StyledTypography = styled.div`
    color: #828181;
    background: #ececec;
    text-align: center;
    border-radius: 100px;
    margin-top: 10px;
`

function CircularBar({ value, color, title, description, className, thickness = 9, size = 100 }) {
    return (
        <Container className={className}>
            <Wrap>
                <StyledBox color={color} position='relative' display='inline-flex'>
                    <CircularProgress
                        color='inherit'
                        thickness={thickness}
                        size={size}
                        variant='determinate'
                        value={value}
                    />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position='absolute'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Typography variant='caption' component='div'>{`${Math.round(value)}%`}</Typography>
                    </Box>
                </StyledBox>
            </Wrap>
            {description && title && (
                <StyledTypography title={description} variant='caption' color='textPrimary'>
                    {title}
                </StyledTypography>
            )}
        </Container>
    )
}

export default CircularBar
