import { apiUrl } from '../../Environments/environment'
import Constants from '../Constants'

const postExcelData = (body) => {
    return new Promise((next) => {
        fetch(apiUrl + 'smartselection/import', {
            method: 'POST',
            headers: Constants.ApiHeader(),
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((json) => next(json))
    })
}

export { postExcelData }
