import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useExcelParse from './useExcelParse'
import Button from '@material-ui/core/Button'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'

const Container = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
`
const TextInfo = styled(Typography)`
    && {
        color: #757575;
        font-size: 14px;
    }
`
const StyledFormControlLabel = styled(FormControlLabel)`
    margin-bottom: 16px;
`

function ManualPerformances({ model, calcTypes }) {
    const { handleChange, handleSubmit, loading, excelPage, setModel, status } = useExcelParse()
    const [selectedTypesCalc, setSelectedTypeCalc] = useState([])
    useEffect(() => {
        setModel(model)
    }, [])

    const CheckboxLabels = ({ label, name, checked, onChange, color, disabled }) => {
        return (
            <StyledFormControlLabel
                labelPlacement='end'
                control={<Checkbox {...{ checked, onChange, name: name, color: color, disabled }} size='small' />}
                {...{ label: <TextInfo>{label}</TextInfo> }}
            />
        )
    }
    useEffect(() => {
        if (excelPage.length) setSelectedTypeCalc(excelPage.map(({ name }) => ({ label: name, checked: false })))
    }, [excelPage])

    const handleChangeType = (event, value) => {
        setSelectedTypeCalc((prev) => {
            const newState = prev.map((obj) => {
                if (obj.label === event.target.name) {
                    return { label: obj.label, checked: value }
                }
                return obj
            })
            return newState
        })
    }

    const typesName = selectedTypesCalc.filter(({ checked }) => checked === true).map(({ label }) => label)
    return (
        <Container>
            {status !== 'stopped' ? (
                `The insertion was done with ${status} status ${
                    status == 'error' ? 'Please make sure of the entered Data' : ''
                }`
            ) : (
                <>
                    <div>
                        <input multiple onChange={handleChange} type='file' accept='.xlsx, .xls' />
                    </div>
                    <br />
                    {excelPage.length > 0 && (
                        <Box sx={{ flexGrow: 1, maxWidth: 752, mt: 2 }}>
                            <Grid container spacing={2}>
                                <Typography sx={{ mt: 4, mb: 2 }} variant='body2' component='div'>
                                    Please choose the needed calculation types from the <b>uploaded excel</b>
                                </Typography>
                                <List>
                                    {selectedTypesCalc.length > 0 &&
                                        selectedTypesCalc.map(({ label, checked }, index) => (
                                            <CheckboxLabels
                                                disabled={!calcTypes.includes(label)}
                                                checked={checked}
                                                key={index}
                                                label={`${label} ${!calcTypes.includes(label) ? '- Not Exist' : ''}`}
                                                name={label}
                                                onChange={handleChangeType}
                                                color='primary'
                                            />
                                        ))}
                                </List>
                            </Grid>
                        </Box>
                    )}
                    {excelPage.length > 0 && typesName.length > 0 && (
                        <Button
                            onClick={() => handleSubmit(typesName, model)}
                            variant='contained'
                            color='primary'
                            endIcon={loading && <HourglassEmptyIcon />}>
                            Insert the Performances
                        </Button>
                    )}
                </>
            )}
        </Container>
    )
}

export default ManualPerformances
