import { useState, useEffect } from 'react'
import xlsxFile from 'read-excel-file'
import { postPerformancesData } from '../../Assets/Interfaces/CalculationInterface'
import { GetAllDataByProduct } from '../../Assets/Interfaces/ProductInterface'
import { GetAllStatusCandidatesByName } from '../../Assets/Interfaces/CandidatesInterface'

export default function useExcelParse() {
    const [productData, setProductData] = useState([])
    const [statusData, setStatusData] = useState([])
    const [dataToInsert, setDataToInsert] = useState([])
    const [excelFile, setExcelFile] = useState([])
    const [excelPage, setExcelPage] = useState([])
    const [excelData, setExcelData] = useState([])
    const [model, setModel] = useState()
    const [status, setStatus] = useState('stopped')

    useEffect(() => {
        if (model) {
            GetAllDataByProduct({ name: model }).then((res) => {
                if (res && res.length) setProductData(res)
            })

            GetAllStatusCandidatesByName(model).then((res) => {
                if (res && res.length) setStatusData(res)
            })
        }
    }, [model])
    const [importResult, setImportResult] = useState([])
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        setExcelFile([])
        setExcelPage([])
        setExcelData([])
        setImportResult([])
        setExcelFile([...event.target.files])
    }

    const filterExcelData = (types) => {
        return new Promise((resolve, reject) => {
            const filteredData = excelData.filter(({ type }) => types.includes(type))
            setExcelData(filteredData)
            resolve()
        })
    }

    const getCandidateBySize = (size) => {
        const candidates = productData.filter((product) =>
            product.Parameters.some((Parameter) => Parameter.Name === 'Sel_sSize' && Parameter.Values[0].Value === size)
        )
        return candidates
    }

    const getCandidateByOptions = (options, candidates) => {
        let candidateList = []
        candidates.map((candidate) => {
            let count = 0
            let filteredCandidate = []
            Object.entries(options).map((option) => {
                const [key, value] = option
                filteredCandidate = candidate.Parameters.filter(
                    (Parameter) => Parameter.Description == key && Parameter.Values[0].Value === value
                )
                if (filteredCandidate.length > 0) {
                    count++
                }
            })
            if (count == Object.entries(options).length) {
                candidateList.push(candidate)
            }
        })
        return candidateList[0]?.candidateId
    }

    const getCandidateId = (perfList, optionsList, type, size, model) => {
        const candidateBySize = getCandidateBySize(size)
        return getCandidateByOptions(optionsList, candidateBySize)
    }
    const insertData = async (data) => {
        const output = await postPerformancesData(data)
        if (output.result == 'success') {
            setStatus('success')
            setTimeout(() => {
                document.location.reload()
            }, 2000)
        } else {
            setStatus('error')
        }
    }
    const handleSubmit = (types, model) => {
        filterExcelData(types).then(() => {
            let dataToInsert = []
            excelData.map(({ perfList, optionsList, type, size }, index) => {
                const candidateId = getCandidateId(perfList, optionsList, type, size, model)
                const statusId = statusData?.find((el) => el.id == candidateId && el.Status[type]?.value == 'Success')
                    ?.Status[type].id
                if (candidateId && statusId && perfList && model) {
                    const insertObj = {
                        perfList,
                        candidateId,
                        model,
                        statusId,
                        type,
                    }
                    dataToInsert.push(insertObj)
                } else {
                    console.error(
                        `Row Error On the Line ${index} , Please make sure that you have an existing candidate for the entered data`,
                        'size:',
                        size,
                        'Options List',
                        optionsList
                    )
                }
            })
            if (dataToInsert.length === excelData.length) {
                insertData(dataToInsert)
                setDataToInsert(dataToInsert)
            } else {
                setStatus('error')
            }
        })
    }

    const getExcelPage = (file) => {
        return xlsxFile(file, { getSheets: true }).then((rows) =>
            rows.filter(({ name }) => !name.startsWith('XX')).map((el) => ({ name: el.name, fileName: file.name }))
        )
    }

    const getAllPages = async () => {
        setLoading(true)
        const pages = await Promise.all(excelFile.map((file) => getExcelPage(file)))
        pages.map((page) => {
            page.map(({ name, fileName }) => {
                setExcelPage((prev) => [...prev, { name, fileName }])
            })
        })
        setLoading(false)
    }

    const optionsConstructor = (optArray, optNames) => {
        let optionsObj = {}
        optArray.map((option, index) => {
            const optionName = optNames[index]
            optionsObj[optionName] = option
        })
        return optionsObj
    }

    const perfConstructor = (perfValues, perfNames, perfUnities) => {
        let perfList = []
        perfValues.map((perf, index) => {
            const perfObj = { name: perfNames[index], unity: perfUnities[index], value: perf }
            perfList.push(perfObj)
        })
        return perfList
    }

    useEffect(() => {
        if (excelFile?.length > 0) {
            getAllPages()
        }
    }, [excelFile])

    useEffect(() => {
        if (excelPage.length > 0 && !loading) {
            excelPage.forEach((element) => {
                xlsxFile(
                    excelFile.find((el) => el.name === element.fileName),
                    { sheet: element.name }
                ).then((rows) => {
                    const performancePosition = rows[0].findIndex((row) => row === 'PERFORMANCES')
                    const optionsDesc = rows[1].slice(1, performancePosition)
                    const PerfNames = rows[1].slice(performancePosition, rows[1].length)
                    const PerfUnities = rows[2].slice(performancePosition, rows[1].length)
                    const values = rows.slice(3, rows.length)
                    values.forEach((col) => {
                        const size = col[0]
                        const optionsList = optionsConstructor(col.slice(1, performancePosition), optionsDesc)
                        const perfValues = col.slice(performancePosition, col.length)
                        const perfList = perfConstructor(perfValues, PerfNames, PerfUnities)
                        setExcelData((prev) => [
                            ...prev,
                            { perfList: perfList, optionsList: optionsList, type: element.name, size: size },
                        ])
                    })
                })
            })
        }
    }, [excelPage, loading])

    return { handleChange, handleSubmit, importResult, loading, excelPage, setModel, status }
}
