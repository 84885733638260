import { useState, useEffect } from 'react'
import xlsxFile from 'read-excel-file'
import { postExcelData } from '../../Assets/Interfaces/SmartselectionInterface'

/**
 * @category customHook smartselection
 * @function
 * @description parse excel fle with precalculed coffeficient for smartselection
 */

export default function useExcelParse() {
    const [excelFile, setExcelFile] = useState([])
    const [excelPage, setExcelPage] = useState([])
    const [excelData, setExcelData] = useState([])
    const [excelNbPages, setExcelNbPages] = useState(undefined)

    const [importResult, setImportResult] = useState([])
    const [loading, setLoading] = useState(false)

    function titleCase(string) {
        return string && string.length > 0 ? string[0]?.toUpperCase() + string?.slice(1).toLowerCase() : ''
    }

    const handleChange = (event) => {
        setExcelFile([])
        setExcelPage([])
        setExcelData([])
        setImportResult([])
        setExcelFile([...event.target.files])
    }

    const fetchImport = async () => {
        setLoading(true)
        setImportResult(await postExcelData(excelData))
        setExcelNbPages(excelData.length)
        setExcelFile([])
        setExcelPage([])
        setExcelData([])
        setLoading(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        fetchImport()
    }

    const getExcelPage = (file) => {
        return xlsxFile(file, { getSheets: true }).then((rows) =>
            rows.filter(({ name }) => !name.startsWith('XX')).map((el) => ({ name: el.name, fileName: file.name }))
        )
    }

    const getAllPages = async () => {
        setLoading(true)

        const pages = await Promise.all(excelFile.map((file) => getExcelPage(file)))

        pages.map((page) => {
            page.map(({ name, fileName }) => {
                setExcelPage((prev) => [...prev, { name, fileName }])
            })
        })
        setLoading(false)
    }

    useEffect(() => {
        if (excelFile?.length > 0) {
            getAllPages()
        }
    }, [excelFile])

    useEffect(() => {
        if (excelPage.length > 0 && !loading) {
            excelPage.forEach((element) => {
                xlsxFile(
                    excelFile.find((el) => el.name === element.fileName),
                    { sheet: element.name }
                ).then((rows) => {
                    const name = rows[0][0].split(':')[1]
                    const mode = rows[1][0].split(':')[1]
                    let array = []

                    if (mode !== 'pressure') {
                        let size = null
                        let options = null
                        let condition = null
                        let condition2 = null
                        let attr = null
                        let values = []

                        rows.forEach((col) => {
                            if (col[1]) size = '' + col[1]
                            if (col[2] && col[2] !== 'Standard') options = col[2] + ''
                            if (col[2] && col[2] === 'Standard') options = null
                            if (col[4] && col[4] !== '') condition = col[4]
                            if (col[4] && (col[4] === '' || col[4] === 'Indoor' || col[4] === 'Outdoor'))
                                condition = null
                            if (col[5] && col[5] !== '') condition2 = col[5]
                            if (col[5] && (col[5] === '' || col[5] === 'Indoor' || col[5] === 'Outdoor'))
                                condition2 = null
                            if (col[6]) attr = col[6]
                            if (col[7])
                                values = [
                                    col[7],
                                    col[8],
                                    col[9],
                                    col[10],
                                    col[11],
                                    col[12],
                                    col[13],
                                    col[14],
                                    col[15],
                                ].filter(Boolean)
                            if (!col[7]) values = []

                            if (array && size && attr && values.length > 0)
                                array.push({
                                    size,
                                    options,
                                    condition,
                                    condition2,
                                    attr: titleCase(attr),
                                    values,
                                })
                        })
                    } else {
                        rows.forEach((col) => {
                            let size = null
                            let options = null
                            let condition = null
                            let attr = null
                            let values = []

                            if (col[1]) size = '' + col[1]
                            if (col[2] && col[2] !== 'Standard') options = col[2] + ''
                            if (col[2] && col[2] === 'Standard') options = null
                            if (col[3] && col[3] !== '') condition = col[3]
                            if (col[4]) attr = col[4]
                            if (col[5]) values = [col[5], col[6], col[7], col[8]]

                            if (array && size && size !== 'Size' && attr && values.length > 0)
                                array.push({
                                    size,
                                    options,
                                    condition,
                                    attr: titleCase(attr),
                                    values,
                                })
                        })
                    }

                    setExcelData((prev) => [...prev, { name, mode, data: array, label: element.name }])
                })
            })
        }
    }, [excelPage, loading])

    return { handleChange, handleSubmit, excelData, importResult, loading, excelNbPages }
}
