import { apiUrl } from '../../Environments/environment'
import Constants from '../Constants'

function AddParameter(parameter, productToUpdate) {
    return new Promise((next) => {
        fetch(apiUrl + 'Products/' + productToUpdate + '/Parameters', {
            method: 'POST',
            headers: Constants.ApiHeader(),
            body: JSON.stringify(parameter),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

function GetAllParameters(product, Type) {
    return new Promise((next) => {
        let url
        if (product && !Type) url = apiUrl + 'Products/' + product + '/Parameters'
        if (product && Type) url = apiUrl + 'Parameters/product/' + product + '?Type=' + Type
        if (!product && Type) url = apiUrl + 'Parameters?Type=' + Type
        fetch(url, {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

function DeleteParameter(ParameterID) {
    return new Promise((next) => {
        fetch(apiUrl + 'Parameters/' + ParameterID, {
            method: 'DELETE',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

export { AddParameter, GetAllParameters, DeleteParameter }
