import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TabConfigurationToPromote from './Components/Tabs/Tab 1 Configuration to promote/TabConfigurationToPromote'
import TabDisplayEcodesignData from './Components/Tabs/Tab 3 Display Ecodesign data/TabDisplayEcodesignData'
import CalcRequestDetails from './Components/CalcRequestDetails/CalcRequestDetails'
import Dashboard from './Components/Dashboard'
import SmartSelection from './Components/SmartSelection'

const Router = () => {
    return (
        <Routes>
            <Route index element={<Navigate to='/configuration' />} />
            <Route exact path='/runCalculation/:productName' element={<CalcRequestDetails />} />
            <Route
                exact
                path='/configuration'
                element={<TabConfigurationToPromote Name='Define configurations to promote' />}
            />
            <Route exact path='/calculation' element={<TabDisplayEcodesignData />} />
            <Route exact path='/dashboard' element={<Dashboard Name='Dashboard' />} />
            <Route exact path='/smartselection' element={<SmartSelection />} />
        </Routes>
    )
}

export default Router
