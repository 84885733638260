import { useState, useEffect } from 'react'
import { GetAllDataByProduct } from '../../../../Assets/Interfaces/ProductInterface'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

export default function useTableDisplayData(product) {
    const location = useLocation()
    const [openTable, setOpenTable] = useState(false)
    const [data, setData] = useState([])
    const [queryParams] = useState(qs.parse(location.search))

    const toggleTable = () => setOpenTable(!openTable)

    useEffect(() => {
        if (queryParams?.product === product) toggleTable()
    }, [queryParams?.product])

    useEffect(() => {
        if (openTable || queryParams?.product === product)
            GetAllDataByProduct(product).then((res) => {
                if (res && res.length) setData(res)
            })
    }, [openTable, queryParams?.product])

    return { data, openTable, toggleTable }
}
