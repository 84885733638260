import { apiUrl } from '../../Environments/environment'
import Constants from '../Constants'

const getPendingCandidate = () => {
    return new Promise((next) => {
        fetch(apiUrl + 'Candidates/pending', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json))
    })
}
const getProgressCandidate = () => {
    return new Promise((next) => {
        fetch(apiUrl + 'Candidates/progress', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json))
    })
}

const saveCalculedCandidates = (options, productName, rangeIndex, user) => {
    return new Promise((next) => {
        fetch(`${apiUrl}Products/${productName}/candidates/?user=${encodeURIComponent(user)}`, {
            method: 'POST',
            headers: Constants.ApiHeader(),
            body: JSON.stringify({ rangeIndex: rangeIndex, options: options }),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

const updateCandidatesRangeIndex = (productName, rangeIndex, user) => {
    return fetch(`${apiUrl}Products/${productName}/${rangeIndex}/candidates/?user=${encodeURIComponent(user)}`, {
        method: 'POST',
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .catch((err) => {
            throw err
        })
}

const getSavedCalculedCandidates = (productName) => {
    return new Promise((next) => {
        fetch(apiUrl + 'Products/' + productName + '/candidates', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

const GetAllCandidatesSizesByName = (productName) => {
    return new Promise((next) => {
        fetch(apiUrl + 'Candidates/' + productName + '/sizes', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

const GetAllCandidatesByNameAndSize = (productName, size) => {
    return new Promise((next) => {
        fetch(apiUrl + 'Candidates/' + productName + '/' + size + '/values', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

const GetAllStatusCandidatesByName = (productName) => {
    return new Promise((next) => {
        fetch(apiUrl + 'Candidates/' + productName + '/status', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
    })
}

export {
    saveCalculedCandidates,
    getSavedCalculedCandidates,
    GetAllCandidatesByNameAndSize,
    GetAllCandidatesSizesByName,
    GetAllStatusCandidatesByName,
    getProgressCandidate,
    getPendingCandidate,
    updateCandidatesRangeIndex,
}
