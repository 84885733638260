import { useEffect, useContext } from 'react'
import { GlobalContext, actionContext } from '../store'
import { io } from 'socket.io-client'
import environment from '../Environments/environment'
import { fetchInitialProduct } from '../api'

export default function useInitData(permissions) {
    const [, dispatch] = useContext(GlobalContext)

    useEffect(() => {
        // only when we have permissions
        if (permissions) {
            // set username
            dispatch({
                type: actionContext.CHANGE_USER_NAME,
                data: permissions.firstName + ' ' + permissions.lastName,
            })
            // initialize websocket
            dispatch({
                type: actionContext.INITIALIZE_WEBSOCKET,
                data: io(environment.api.socketUrl, { transports: ['websocket', 'polling'] }),
            })
            // fetch products
            fetchInitialProduct().then((res) => {
                dispatch({
                    type: actionContext.FETCH_PRODUCTS_AVAILABLE,
                    data: res,
                })
            })
        }
    }, [permissions])

    return {}
}
