import React, { useState } from 'react'
import styled from 'styled-components'

import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

const StyledTableContainer = styled(TableContainer)`
    th,
    tr,
    td {
        color: #777;
    }
    tr:nth-child(odd) {
        background: #f3f3f7;
    }
    th {
        background: #3f51b5;
        color: #fff;
        font-weight: bold;
    }
`

const SeeMore = styled.div`
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
`

const DenseTable = ({ rows: allRows, resume = null, className }) => {
    const [rows] = useState(resume ? allRows.slice(0, resume) : allRows)

    return (
        <StyledTableContainer className={className}>
            <Table size='small'>
                {!resume && (
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>ProductName</TableCell>
                            <TableCell align='right'>CalcType</TableCell>
                            <TableCell align='right'>Date</TableCell>
                            <TableCell align='right'>UserName</TableCell>
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {rows?.map((row, index) => (
                        <TableRow key={row.name}>
                            <TableCell scope='row'>{index + 1}.</TableCell>
                            <TableCell scope='row'>{row.ProductName}</TableCell>
                            <TableCell align='right'>{row.CalcType}</TableCell>
                            <TableCell align='right'>{new Date(+row.Date).toLocaleString()}</TableCell>
                            <TableCell align='right'>by {row.UserName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {resume && allRows.length > resume && <SeeMore> Click on button to see more...</SeeMore>}
        </StyledTableContainer>
    )
}

export default DenseTable
