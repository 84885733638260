import React from 'react'
import styled from 'styled-components'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const StyledDialogContent = styled(DialogContent)`
    .control {
        margin: 14px 0;
    }

    .control select {
        background: #f7f7f7;
    }
    && {
        padding: 8px;
        display: flex;
        flex-direction: column;
        color: grey;
    }
`

const StyledDialogTitle = styled(DialogTitle)`
    background: #3f51b5;
    color: white;
    border: 8px solid white;
`

const StyledDialog = styled(Dialog)`
    box-shadow: 0 0 3px 0px #212121;
`

const Modal = ({
    children,
    title,
    handleClose,
    handleSubmit,
    handleReset,
    open,
    withButton = true,
    noTitle = false,
}) => {
    return (
        <StyledDialog open={open} onClose={handleClose} key={`modal-${title}`}>
            {!noTitle && <StyledDialogTitle>{title}</StyledDialogTitle>}
            <StyledDialogContent>{children}</StyledDialogContent>
            {withButton && !noTitle && (
                <DialogActions>
                    {handleClose && (
                        <Button onClick={handleClose} variant='contained'>
                            cancel
                        </Button>
                    )}

                    {handleReset && (
                        <Button disabled={!handleReset} onClick={handleReset} variant='contained' color='primary'>
                            reset
                        </Button>
                    )}

                    {handleSubmit && (
                        <Button disabled={!handleSubmit} onClick={handleSubmit} variant='contained' color='primary'>
                            apply
                        </Button>
                    )}
                </DialogActions>
            )}
        </StyledDialog>
    )
}

export default Modal
