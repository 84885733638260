import { useEffect, useState } from 'react'

const useFilter = (data) => {
    const [sizes, setSizes] = useState([])
    const [filter, setFilter] = useState({})
    const [dataFiltred, setDataFiltred] = useState([])
    const [open, setOpen] = useState(false)

    const handleChange = ({ target: { value, name } }) => {
        setFilter((prev) => ({ ...prev, [name]: value }))
    }

    const toggleFilter = () => {
        setOpen((prev) => !prev)
    }

    const filterDataBySize = (candidateList) =>
        candidateList.filter((candidate) =>
            candidate.Parameters.find((parameter) => parameter.Name === 'Sel_sSize').Values[0].Value.startsWith(
                filter.size
            )
        )

    const handleSubmit = () => {
        setDataFiltred(filterDataBySize(data))
        toggleFilter()
    }

    const getAllSize = (candidateList) => [
        ...new Set(
            candidateList.map((candidate) => {
                const sizeArray = candidate.Parameters.map((parameter) => {
                    if (parameter.Name === 'Sel_sSize') return parameter.Values[0].Value
                })
                return sizeArray.filter((el) => el)[0]
            })
        ),
    ]

    useEffect(() => {
        if (data && data.length) {
            setSizes(getAllSize(data))
        }
    }, [data])

    return { filter, open, sizes, dataFiltred, handleChange, toggleFilter, handleSubmit }
}

export default useFilter
