import React, { useEffect, useState, useContext } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import DenseTable from './Table'
import DetailModal from './Modal'
import { getProgressCandidate, getPendingCandidate } from '../../Assets/Interfaces/CandidatesInterface'
import { GlobalContext } from '../../store'

const InfoCalculation = () => {
    const [state] = useContext(GlobalContext)
    const [runningCalculation, setRunningCalculation] = useState({
        pending: 0,
        progress: 0,
    })
    const [progress, setProgress] = useState([])
    const [pending, setPending] = useState([])

    const [openDetailPending, setOpenDetailPending] = useState(false)
    const handleOpenDetailPending = () => {
        progress.length > 0 && setOpenDetailPending(true)
    }
    const handleCloseDetailPending = () => setOpenDetailPending(false)

    const [openDetailProgress, setOpenDetailProgress] = useState(false)
    const handleOpenDetailProgress = () => {
        progress.length > 0 && setOpenDetailProgress(true)
    }
    const handleCloseDetailProgress = () => setOpenDetailProgress(false)

    useEffect(() => {
        if (state.socket) state.socket.on('calculation-info', (dataSocket) => setRunningCalculation(dataSocket))
    }, [state.socket])

    useEffect(() => {
        if (runningCalculation.pending || runningCalculation.progress) {
            runningCalculation.progress && getProgressCandidate().then((res) => setProgress(res))
            runningCalculation.pending && getPendingCandidate().then((res) => setPending(res))
        }
        runningCalculation.pending === 0 && setPending([])
        runningCalculation.progress === 0 && setProgress([])
    }, [runningCalculation.pending, runningCalculation.progress])

    return (
        <>
            <DetailModal
                title='Calculation in pending'
                open={openDetailPending}
                rows={pending}
                handleClose={handleCloseDetailPending}
            />
            <DetailModal
                title='Calculation in progress'
                open={openDetailProgress}
                rows={progress}
                handleClose={handleCloseDetailProgress}
            />
            <div>
                <ButtonGroup disableElevation variant='text' size='large' color='inherit'>
                    <Button color='inherit' onClick={handleOpenDetailPending}>
                        <Tooltip title={pending.length > 0 ? <DenseTable rows={pending} resume={5} /> : <></>}>
                            <div>
                                Pending: <b>{runningCalculation.pending}</b>
                            </div>
                        </Tooltip>
                    </Button>
                    <Button color='inherit'>
                        <Tooltip
                            onClick={handleOpenDetailProgress}
                            title={progress.length > 0 ? <DenseTable rows={progress} resume={5} /> : <></>}>
                            <div>
                                Progress: <b>{runningCalculation.progress}</b>
                            </div>
                        </Tooltip>
                    </Button>
                </ButtonGroup>
            </div>
        </>
    )
}

export default InfoCalculation
