import React, { useReducer, createContext } from 'react'
import reducter from './reducer'
import action from './actions'
import selector from './selector'

export const GlobalContext = createContext()
export const actionContext = action
export const selectorContext = selector
export const isDEV = process.env.NODE_ENV === 'development'

const initialState = {
    ui: {
        calculationRun: process.env.NB_CALC_LIMIT || 10,
    },
    user: {
        name: '',
    },
    products: [],
    socket: undefined,
    functions: {
        reloadProducts: undefined,
    },
}

export const ContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducter, initialState)

    return <GlobalContext.Provider value={[state, dispatch]}>{props.children}</GlobalContext.Provider>
}
