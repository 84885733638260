import React, { useState, useEffect, useContext } from 'react'
import { FilterAndReconfigure } from '@carrier/workflowui-globalfunctions'
import { rulesAppServices } from '../../../Environments/environment'
import { groupBy } from '../../../Assets/Helpers'
import { GetAllParameters } from '../../../Assets/Interfaces/ParameterInterface'
import { GetRuleset } from '../../../Assets/Interfaces/RulesEngineInterface'
import { GlobalContext, actionContext } from '../../../store'

import OptionColumn from './OptionColumn'
import CandidatesPopup from './CandidatesPopup/CandidatesPopup'
import Modal from '../../Common/Modal/index.jsx'
import Grid from '@material-ui/core/Grid'
import { fetchInitialProduct } from '../../../api'
import Constants from '../../../Assets/Constants.js'

function TabConfigurationToPromote() {
    const [state, dispatch] = useContext(GlobalContext)
    const [Models, setModels] = useState([])
    const [DisplayPopup, setDisplayPopup] = useState(false)
    const [SelectedModelIndex, setSelectedModelIndex] = useState()
    const [loading, setLoading] = useState(null)

    useEffect(() => {
        fetchInitialProduct().then((res) => {
            dispatch({
                type: actionContext.FETCH_PRODUCTS_AVAILABLE,
                data: res,
            })
        })
    }, [])

    useEffect(() => {
        let ModelsAvailable = []
        FilterAndReconfigure(rulesAppServices, 'PCU_EMEA', 'SMART', null, ['Sel_sModel'], [], null).then((result) => {
            result.VariableDomains.find(({ Name }) => Name === 'Sel_sModel').Values.forEach(
                ({ Value, Attributes: { Description, RangeIndex }, State }) => {
                    if (State === 1)
                        ModelsAvailable.push({
                            RulesValue: Value,
                            Description,
                            RangeIndex: RangeIndex !== '' ? RangeIndex : Constants.DEFAULT_RANGE_INDEX,
                            Parameters: [],
                        })
                }
            )
            GetAllParameters(null, 'in').then((Params) => {
                let GroupedParam = groupBy(Params, 'Product')
                ModelsAvailable.forEach((Model) => {
                    Model.Parameters = GroupedParam[Model.RulesValue] ? GroupedParam[Model.RulesValue] : []
                    const productFromDB = state.products.find((p) => p.name === Model.RulesValue)
                    Model.Status = productFromDB ? productFromDB.calcStatus : 'NotValidated'
                })
                setModels(ModelsAvailable)
            })
        })
    }, [state.products])

    useEffect(() => {
        if (state.socket) {
            state.socket.on('ParameterAdded', (parameter) => SaveParameter(parameter))
            state.socket.on('ParameterDeleted', (id, Model) => DeleteParameter(id, Model))
            state.socket.on('ParameterLoading', (model, value) => {
                loadingParameter(model, value)
            })
        }
    }, [state.socket])

    function SaveParameter(parameter) {
        setModels((OldModels) => {
            let UpdatedModels = [...OldModels]
            let ModelIndex = GetModelIndex(UpdatedModels, parameter.Product)
            let UpdatedParameters = [...UpdatedModels[ModelIndex].Parameters, parameter]
            UpdatedModels[ModelIndex].Parameters = UpdatedParameters
            return UpdatedModels
        })
    }

    function loadingParameter(model, value) {
        setLoading((prev) => ({ ...prev, [model]: value }))
    }

    function DeleteParameter(ID, Model) {
        setModels((OldModels) => {
            let UpdatedModels = [...OldModels]
            let ModelIndex = GetModelIndex(UpdatedModels, Model)
            let UpdatedParameters = [...UpdatedModels[ModelIndex].Parameters].filter((o) => o.Id !== ID)
            UpdatedModels[ModelIndex].Parameters = UpdatedParameters
            return UpdatedModels
        })
    }
    function GetModelIndex(UpdatedModels, Model) {
        return UpdatedModels.findIndex((m) => m.RulesValue === Model)
    }

    async function onSyncCandidates(Model) {
        let ModelIndex = GetModelIndex(Models, Model)
        setSelectedModelIndex(ModelIndex)
        setDisplayPopup(true)
        if (Models[ModelIndex].Ruleset === undefined) {
            let Ruleset = await GetRuleset(Model)
            onRulesetDefined(Model, Ruleset)
        }
    }

    function onRulesetDefined(Model, Ruleset) {
        setModels((OldModels) => {
            let UpdatedModels = [...OldModels]
            let ModelIndex = GetModelIndex(UpdatedModels, Model)
            UpdatedModels[ModelIndex].Ruleset = Ruleset
            return UpdatedModels
        })
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid justifyContent='center' container item xs={12} spacing={2}>
                    {Models.map((model, index) => (
                        <Grid key={`card-${index}`} item xs={1.1}>
                            <OptionColumn
                                key={index}
                                ModelIndex={index}
                                Data={model}
                                onSaveParameter={(parameter, Model) =>
                                    state.socket.emit('AddParameter', parameter, Model, (newParameter) =>
                                        SaveParameter(newParameter)
                                    )
                                }
                                loading={loading ? loading[model.RulesValue] : null}
                                onDeleteParameter={(ID, Model) =>
                                    state.socket.emit('DeleteParameter', ID, Model, (id, selectedModel) =>
                                        DeleteParameter(id, selectedModel)
                                    )
                                }
                                onSyncCandidates={onSyncCandidates}
                                onRulesetDefined={onRulesetDefined}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Modal
                open={DisplayPopup}
                withButton={false}
                title={
                    (SelectedModelIndex !== undefined ? Models[SelectedModelIndex].Description : null) + ' Candidates'
                }
                handleClose={() => setDisplayPopup(false)}>
                <CandidatesPopup open={DisplayPopup} Model={Models[SelectedModelIndex]} />
            </Modal>
        </>
    )
}

export default TabConfigurationToPromote
