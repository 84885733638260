import React from 'react'
import styled from 'styled-components'

const Filler = styled.div`
    width: ${({ completed }) => completed + '%'};
    background-color: ${({ bg }) => bg};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const Label = styled.span`
    padding: 5px;
    color: white;
    font-weight: bold;
`

const Wrap = styled.div`
    height: ${({ height }) => `${height}px`};
    display: flex;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 50px;
    margin: 5px 0;
    overflow: hidden;
    z-index: 2;
`

const ProgressBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ececec;
    border-radius: 50px;
    color: #828181;
    z-index: 1;
    width: 120px;
    height: ${({ height }) => `${height}px`};
    margin-left: -17px;
`

const FilledBar = ({ success = 0, fail = 0, unable = 0, name, height = 20, breakNumber = 7 }) => (
    <ProgressBarContainer>
        <Wrap height={height}>
            <Filler completed={success} bg={'#71ab71'}>
                {success && success >= breakNumber ? <Label>{`${success}%`}</Label> : null}
            </Filler>
            <Filler completed={fail} bg={'#f16969'}>
                {fail && fail >= breakNumber ? <Label>{`${fail}%`}</Label> : null}
            </Filler>
            <Filler completed={unable} bg={'#5D6D7E'}></Filler>
        </Wrap>
        <Title height={height}>{name}</Title>
    </ProgressBarContainer>
)

export default FilledBar
