import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import AddOptionDropDown from './AddOptionDropDown/AddOptionDropDown'
import Modal from '../../Common/Modal/index'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '../../Common/Avatar'
import Constants from '../../../Assets/Constants'

const StyledListItem = styled(ListItem)`
    && {
        background: #f3f3f7;
        border-bottom: 1px solid #e0e0e0;
    }
`

const StyledList = styled(List)`
    && {
        padding: 0;
    }
`

const StyledCardHeader = styled(CardHeader)`
    word-break: break-word;
    border-bottom: 1px solid #e0e0e0;
    max-width: 150px;
    text-align: -webkit-center;
`

const StyledCardActions = styled(CardActions)`
    background: #f3f3f7;
`

const StyledButtonGroup = styled(ButtonGroup)`
    width: 100%;
`

const StyledCardContent = styled(CardContent)`
    && {
        padding: 0;
    }
`

const StyledContainerLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
`

const StyledCard = styled(Card)`
    width: 170px;
`

function OptionColumn({ Data, onDeleteParameter, onRulesetDefined, onSaveParameter, onSyncCandidates, loading }) {
    const [haveFixedPump, setHaveFixedPump] = useState(false)
    const [haveVariablePump, setHaveVariablePump] = useState(false)
    const [displayOptionChangePopup, setDisplayOptionChangePopup] = useState(false)
    const [parameterDelete, setParameterDelete] = useState({ id: null, value: null, description: null })
    const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false)

    const statusColors = {
        Validated: Constants.COLORS.validated,
        In_Progress: Constants.COLORS.inprogress,
        Error: Constants.COLORS.error,
        NotValidated: Constants.COLORS.disable,
    }

    const subHeader =
        Data.RulesValue !== Data.Description ? (
            <>
                {Data.RulesValue}{' '}
                <Avatar color={statusColors[Data.Status]}>
                    {Data.RangeIndex !== '' ? Data.RangeIndex : Constants.DEFAULT_RANGE_INDEX}
                </Avatar>
            </>
        ) : (
            <Avatar color={statusColors[Data.Status]}>
                {Data.RangeIndex !== '' ? Data.RangeIndex : Constants.DEFAULT_RANGE_INDEX}
            </Avatar>
        )

    const handleModalClose = (setDisplay) => {
        setDisplay(false)
    }
    const handleModalOpen = (setDisplay) => {
        setDisplay(true)
    }

    const handleOptionChangeApply = () => {
        onDeleteParameter(parameterDelete.id, parameterDelete.value)
        setDisplayOptionChangePopup(false)
    }

    const handleConfirmationApply = () => {
        onSyncCandidates(Data.RulesValue)
        setDisplayConfirmationPopup(false)
    }

    const handleDeleteParameter = (id, rulesValue, description) => {
        setParameterDelete({ id, value: rulesValue, description })
        handleModalOpen(setDisplayOptionChangePopup)
    }

    useEffect(() => {
        if (Data.Parameters.filter((el) => el.PumpType && el.PumpType.includes('IsVariablePump')).length > 0)
            setHaveVariablePump(true)
        else setHaveVariablePump(false)
        if (Data.Parameters.filter((el) => el.PumpType && el.PumpType.includes('IsFixedPump')).length > 0)
            setHaveFixedPump(true)
        else setHaveFixedPump(false)
    }, [Data.Parameters])

    return (
        <>
            {displayOptionChangePopup && (
                <Modal
                    open={displayOptionChangePopup}
                    handleClose={() => handleModalClose(setDisplayOptionChangePopup)}
                    handleSubmit={handleOptionChangeApply}
                    title={parameterDelete.value}>
                    <p>Are you sure you want to delete option {parameterDelete.description} ? </p>
                    <p>
                        Modifying the options will erase all the calculations already made for the{' '}
                        {parameterDelete.value}.
                    </p>
                    <b>
                        You must re-run the candidates before re-running the calculations for the{' '}
                        {parameterDelete.value}.
                    </b>
                </Modal>
            )}
            {displayConfirmationPopup && (
                <Modal
                    open={displayConfirmationPopup}
                    handleClose={() => handleModalClose(setDisplayConfirmationPopup)}
                    handleSubmit={handleConfirmationApply}
                    title={Data.Description}>
                    <p>Are you sure you want to regenerate candidates for the whole {Data.Description} range ?</p>
                    <p>Regenerating the candidates will erase all the calculations and invalidate the range.</p>
                    <b>You must re-run all calculations and re-validate the whole range.</b>
                </Modal>
            )}
            <StyledCard variant='outlined'>
                <StyledCardHeader
                    action={
                        <IconButton color='primary'>
                            <PlayCircleFilledWhiteIcon
                                onClick={() =>
                                    Data.Status === Constants.STATUS.VALIDATED
                                        ? setDisplayConfirmationPopup(true)
                                        : onSyncCandidates(Data.RulesValue)
                                }
                            />
                        </IconButton>
                    }
                    title={Data.Description}
                    subheader={subHeader}
                />

                <StyledCardContent>
                    <StyledList>
                        <StyledListItem dense>
                            <b>option</b>
                        </StyledListItem>
                        {loading && (
                            <StyledContainerLoading>
                                <CircularProgress disableShrink />
                            </StyledContainerLoading>
                        )}
                        {!loading &&
                            Data.Parameters &&
                            Data.Parameters.filter((option) => !option.PumpType).map((e, index) => (
                                <ListItem dense divider key={'list-option-' + index}>
                                    <ListItemText primary={e.Description} />
                                    <ListItemSecondaryAction>
                                        <IconButton size='small' edge='end' aria-label='delete'>
                                            <DeleteIcon
                                                fontSize='small'
                                                onClick={() =>
                                                    handleDeleteParameter(e.Id, Data.RulesValue, e.Description)
                                                }
                                            />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                    </StyledList>
                    <StyledList>
                        <StyledListItem dense>
                            <b>pump</b>
                        </StyledListItem>
                        {!loading &&
                            Data.Parameters &&
                            Data.Parameters.filter((option) => option.PumpType).map((e, index) => (
                                <ListItem dense divider key={'list-pump-' + index}>
                                    <ListItemText
                                        primary={`${e.Description} ${
                                            e.PumpType?.trim() === 'IsVariablePump' ? '(.V)' : '(.F)'
                                        }`}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton size='small' edge='end' aria-label='delete'>
                                            <DeleteIcon
                                                size='small'
                                                onClick={() =>
                                                    handleDeleteParameter(e.Id, Data.RulesValue, e.Description)
                                                }
                                            />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                    </StyledList>
                    {loading && (
                        <StyledContainerLoading>
                            <CircularProgress disableShrink />
                        </StyledContainerLoading>
                    )}
                </StyledCardContent>
                {!loading && (
                    <StyledCardActions disableSpacing>
                        <StyledButtonGroup size='small' aria-label='small outlined button group'>
                            <AddOptionDropDown
                                type='option'
                                Model={Data.RulesValue}
                                Parameters={Data.Parameters}
                                Ruleset={Data.Ruleset}
                                onParameterClick={(Parameter) => onSaveParameter(Parameter, Data.RulesValue)}
                                onRulesetDefined={onRulesetDefined}
                            />
                            <AddOptionDropDown
                                type='pump'
                                haveVariablePump={haveVariablePump}
                                haveFixedPump={haveFixedPump}
                                Model={Data.RulesValue}
                                Parameters={Data.Parameters}
                                Ruleset={Data.Ruleset}
                                onParameterClick={(Parameter) => onSaveParameter(Parameter, Data.RulesValue)}
                                onRulesetDefined={onRulesetDefined}
                            />
                        </StyledButtonGroup>
                    </StyledCardActions>
                )}
            </StyledCard>
        </>
    )
}

export default OptionColumn
