import React from 'react'
import appConfig from '../../Environments/environment'
import { logout } from '@carrier/reactauthwrapper'
import styled from 'styled-components'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import IconButton from '@material-ui/core/IconButton'
import InfoCalculation from './InfoCalculation'
import Stepper from '../Stepper/Stepper'
import packageJson from '../../../package.json'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0px;
    max-width: calc(100% - 0px);
    width: calc(100% - 0px);
    z-index: 3;
    flex-direction: column;
`

const Wrap = styled.div`
    background-color: #3f51b5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0px;
    width: 100%;
    z-index: 3;
    color: white;
`

const User = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const StyledCancelRoundedIcon = styled(CancelRoundedIcon)`
    color: white;
`

const Title = styled.h3`
    margin: 0;
    margin-left: 10px;
`

function Header(props) {
    const handleClick = () => logout(appConfig.api)

    return (
        <Container>
            <Wrap>
                <Title>Ecodesign Wizard {packageJson.version}</Title>
                <InfoCalculation />
                <User>
                    <div className='Header-UserName'>{props.UserName}</div>
                    <IconButton color='white' onClick={handleClick}>
                        <StyledCancelRoundedIcon />
                    </IconButton>
                </User>
            </Wrap>
            <Stepper />
        </Container>
    )
}

export default Header
