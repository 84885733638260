import React, { useState, useEffect } from 'react'
import Modal from '../../Common/Modal'
import styled from 'styled-components'

import { GetAllCalcTpe } from '../../../Assets/Interfaces/RulesEngineInterface'

const WrapColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100px;
`

const WrapRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Input = ({ name, handleChange, checked, label }) => {
    return (
        <WrapRow>
            <input onChange={handleChange} type='checkbox' id={name} name={name} checked={checked} />
            <label htmlFor={name}>{label}</label>
        </WrapRow>
    )
}

const CalculationModal = ({ handleSubmit, handleClose, handleChange, handleAllChange, selected, open, model }) => {
    const [calculationList, setCalculationList] = useState([])

    useEffect(() => {
        GetAllCalcTpe(model).then((result) => {
            setCalculationList(result.filter(Boolean))
        })
    }, [])

    return (
        <Modal open={open} handleClose={handleClose} handleSubmit={handleSubmit} title='Calculate candidates'>
            <WrapRow>
                <WrapColumn>
                    <Input
                        checked={Object.entries(selected)
                            .filter((s) => calculationList.includes(s[0]))
                            .map((s) => s[1])
                            .every(Boolean)}
                        label='select all'
                        name='selectall'
                        handleChange={(e) => handleAllChange(calculationList, e)}
                    />
                    {calculationList.map((calcType) => (
                        <Input
                            checked={selected[calcType]}
                            label={calcType}
                            name={calcType}
                            handleChange={handleChange}
                        />
                    ))}
                </WrapColumn>
            </WrapRow>
        </Modal>
    )
}

export default CalculationModal
