import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import appConfig from './Environments/environment'
import { ReactAuthWrapper } from '@carrier/reactauthwrapper'
import { BrowserRouter } from 'react-router-dom'
import { ContextProvider } from './store/index'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ececec;
}
`

ReactDOM.render(
    <BrowserRouter>
        <GlobalStyle />
        <ContextProvider>
            <ReactAuthWrapper appConfig={appConfig.api}>
                <App />
            </ReactAuthWrapper>
        </ContextProvider>
    </BrowserRouter>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorkerRegistration.register()
