import { useState, useEffect } from 'react'
import { GetRuleset } from '../../../../Assets/Interfaces/RulesEngineInterface'
import { rulesAppServices } from '../../../../Environments/environment'
import { FilterAndReconfigure } from '@carrier/workflowui-globalfunctions'

export default function useAddOptionDropDown(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [FullOptionsList, setFullOptionsList] = useState([])
    const [options, setOptions] = useState([])
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.Parameters) setOptions(FilterOptions(FullOptionsList))
    }, [props.Parameters, props.haveVariablePump, props.haveFixedPump])

    async function LoadOption() {
        let ruleset = props.Ruleset
        setIsOpen(!isOpen)
        if (!isOpen) {
            setLoading(true)
            if (ruleset === undefined) {
                ruleset = await GetRuleset(props.Model)
                props.onRulesetDefined(props.Model, ruleset)
                GetOptionsList(ruleset)
            } else GetOptionsList(ruleset)
        }
    }

    async function GetOptionsList(ruleset) {
        if (FullOptionsList.length === 0) {
            let Assignment = [{ Name: 'Sel_sModel', Value: props.Model }]
            let ConfRulesJSON = await FilterAndReconfigure(
                rulesAppServices,
                'PCU_EMEA',
                ruleset,
                ['OPT'],
                null,
                Assignment,
                ''
            )

            const filterOptions = (list) =>
                list.filter(
                    (element) =>
                        element.Name.startsWith('Opt_b') &&
                        !element.Name.includes('.') &&
                        !element.Values[0].Attributes.IsFixedPump
                )

            const filterPump = (list) =>
                list.filter(
                    (element) =>
                        element.Name.startsWith('Opt_b') &&
                        !element.Name.includes('.') &&
                        element.Values[0].Attributes.IsFixedPump
                )

            const OptionPropList =
                props.type === 'option'
                    ? filterOptions(ConfRulesJSON.VariableDomains)
                    : filterPump(ConfRulesJSON.VariableDomains)

            let OptionList = props.type === 'option' ? [{ Name: 'Sel_sSize', Description: 'Size' }] : []
            OptionPropList.forEach((o) => {
                let newAdd = null
                if (o.Values[0].Attributes.OptNumber)
                    newAdd = {
                        Name: o.Name,
                        Description: o.Values[0].Attributes.OptNumber,
                    }
                if (o.Values[0].Attributes.IsFixedPump === 'TRUE')
                    newAdd = {
                        ...newAdd,
                        PumpType: 'IsFixedPump',
                    }
                if (o.Values[0].Attributes.IsVariablePump === 'TRUE')
                    newAdd = {
                        ...newAdd,
                        PumpType: 'IsVariablePump',
                    }

                if (newAdd) OptionList.push(newAdd)
            })
            setFullOptionsList(OptionList)
            if (props.Parameters) setOptions(FilterOptions(OptionList))
            else setOptions(OptionList)
            setLoading(false)
        } else setLoading(false)
    }

    function FilterOptions(OptionList) {
        let FilteredOptions = OptionList.filter((OLArray) => {
            return (
                props.Parameters.filter((ParametersArray) => {
                    return ParametersArray.Name === OLArray.Name
                }).length === 0
            )
        })

        if (props.haveVariablePump)
            FilteredOptions = FilteredOptions.filter((option) => option.PumpType !== 'IsVariablePump')

        if (props.haveFixedPump) FilteredOptions = FilteredOptions.filter((option) => option.PumpType !== 'IsFixedPump')

        return FilteredOptions
    }

    function displayPumpType(PumpType) {
        if (PumpType === 'IsVariablePump') return ' (V.)'
        if (PumpType === 'IsFixedPump') return ' (F.)'
    }

    function onAddParam(index) {
        let parameter = options[index]
        parameter.Type = 'in'
        props.onParameterClick(parameter, props.Model)
    }

    return { options, displayPumpType, setIsOpen, onAddParam, LoadOption, Loading, isOpen }
}
