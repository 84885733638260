const { REACT_APP_ROOT_API, REACT_APP_RULES, REACT_APP_AUTHENTICATION } = process.env

export const apiUrl =
    location.hostname === 'localhost' || location.hostname === '127.0.0.1'
        ? `http://localhost:8080${REACT_APP_ROOT_API}`
        : `${window.location.origin}${REACT_APP_ROOT_API}`
export const socketUrl =
    location.hostname === 'localhost' || location.hostname === '127.0.0.1'
        ? 'http://localhost:8080'
        : `${window.location.origin}`
export const uiUrl = `${REACT_APP_AUTHENTICATION}`
export const rulesAppServices = `${REACT_APP_RULES}`

export const appConfig = {
    api: {
        uiUrl,
        socketUrl,
    },
}
export default appConfig
