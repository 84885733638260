import React from 'react'
import Modal from '../../../Common/Modal'
import MenuItem from '@material-ui/core/MenuItem'

import TextField from '@material-ui/core/TextField'

const FilterModal = ({ handleSubmit, handleClose, handleChange, handleReset, open, filter, title, list }) => {
    const { size } = filter

    return (
        <>
            <Modal
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                title={title}
            >
                <TextField
                    id='outlined-select-size'
                    select
                    label='Size'
                    name='size'
                    value={size}
                    onChange={handleChange}
                    variant='outlined'
                >
                    {list.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Modal>
        </>
    )
}

export default FilterModal
