import { apiUrl } from '../../Environments/environment'
import Constants from '../Constants'

function GetAllProduct() {
    return new Promise((next) => {
        fetch(apiUrl + 'Products/', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json.result))
            .catch((error) => next(error))
    })
}

function GetStats(product) {
    return new Promise((next) => {
        fetch(apiUrl + 'Products/stat/' + product, {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json))
            .catch((error) => next(error))
    })
}

function GetAllDataByProduct(product) {
    return new Promise((next) => {
        fetch(apiUrl + 'Candidates/' + product.name + '/data', {
            method: 'GET',
            headers: Constants.ApiHeader(),
        })
            .then((res) => res.json())
            .then((json) => next(json))
            .catch((err) => {
                console.warn('GetAllDataByProduct: ', err.message)
                return next(null)
            })
    })
}

function deleteProductByName(product) {
    return new Promise((next) => {
        fetch(apiUrl + 'Products/' + product.name + '/candidates', {
            method: 'DELETE',
            headers: Constants.ApiHeader(),
        })
            .then((res) => next(res))
            .catch((err) => {
                console.warn('deleteProductByName: ', err.message)
                next(err)
            })
    })
}

export { GetAllProduct, GetAllDataByProduct, GetStats, deleteProductByName }
