import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import StorageIcon from '@material-ui/icons/Storage'
import TableDetail from './TableDetail'

import { findRoundedOnAllOutput } from '../../../../Assets/mappingData'

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
            lineHeight: 1,
        },
    },
})

const SyledChip = styled.div`
    background: ${({ value }) => (value ? ' #71ab71' : '#f16969')};
    color: white;
    padding: 2px 8px;
    margin: 1px;
    font-size: 13px;
    border-radius: 2px;
    min-width: 32px;
    display: inline-block;
    text-align: center;
`

const ContainerPreviewData = styled.div`
    font-size: 12px;
    padding: 2px 0;
`

const PreviewData = ({ data, name }) => {
    const res = data.find(({ name: calculedName }) => name === calculedName)
    return (
        <>
            {res && (
                <ContainerPreviewData>
                    <div className='preview-data'>
                        {res.name}: <b>{parseFloat(res.value).toFixed(findRoundedOnAllOutput(res.name))}</b>
                    </div>
                </ContainerPreviewData>
            )}
        </>
    )
}

const StyledPreviewData = styled(PreviewData)`
    font-size: 13px;
    margin: 5px;
`

const Row = (props) => {
    const {
        row: { Parameters },
    } = props

    const [open, setOpen] = useState(false)
    const classes = useRowStyles()

    const size = Parameters.map((el) => {
        if (el.Description === 'Size') return el.Values[0].Value
    }).filter(Boolean)
    const optionsValid = Parameters.map((el) => {
        if (el.Values[0].Value === 'TRUE')
            return (
                <SyledChip key={'option-valid-' + el.Description} value={true}>
                    {el.Description}
                </SyledChip>
            )
    }).filter(Boolean)

    const extractCalculcationData = (type) =>
        Parameters.map((el) => {
            if (el.Type === 'out' && el.Values[0].Status[0].CalcType?.includes(type))
                return {
                    name: el.Name,
                    value: el.Values[0].Value,
                    type,
                    date: el.Values[0].Status[0]?.Date,
                    time: el.Values[0].Status[0]?.Time,
                    unit: el.Unit,
                }
        }).filter(Boolean)

    const seerLT = extractCalculcationData('seerLT')
    const seerMT = extractCalculcationData('seerMT')
    const seprHT = extractCalculcationData('seprHT')
    const seprMT = extractCalculcationData('seprMT')
    const scopLTa = extractCalculcationData('scopLTa')
    const scopLTw = extractCalculcationData('scopLTw')
    const scopLTc = extractCalculcationData('scopLTc')
    const scopITa = extractCalculcationData('scopITa')
    const scopITw = extractCalculcationData('scopITw')
    const scopITc = extractCalculcationData('scopITc')
    const scopMTa = extractCalculcationData('scopMTa')
    const scopMTw = extractCalculcationData('scopMTw')
    const scopMTc = extractCalculcationData('scopMTc')
    const scopHTa = extractCalculcationData('scopHTa')
    const scopHTw = extractCalculcationData('scopHTw')
    const scopHTc = extractCalculcationData('scopHTc')
    const scopbLTa = extractCalculcationData('scopbLTa')
    const scopbLTw = extractCalculcationData('scopbLTw')
    const scopbLTc = extractCalculcationData('scopbLTc')
    const scopbITa = extractCalculcationData('scopbITa')
    const scopbITw = extractCalculcationData('scopbITw')
    const scopbITc = extractCalculcationData('scopbITc')
    const scopbMTa = extractCalculcationData('scopbMTa')
    const scopbMTw = extractCalculcationData('scopbMTw')
    const scopbMTc = extractCalculcationData('scopbMTc')
    const scopbHTa = extractCalculcationData('scopbHTa')
    const scopbHTw = extractCalculcationData('scopbHTw')
    const scopbHTc = extractCalculcationData('scopbHTc')

    const haveOUtput =
        seerLT.length +
            seerMT.length +
            seprHT.length +
            seprMT.length +
            scopLTa.length +
            scopLTw.length +
            scopLTc.length +
            scopITa.length +
            scopITw.length +
            scopITc.length +
            scopMTa.length +
            scopMTw.length +
            scopMTc.length +
            scopHTa.length +
            scopHTw.length +
            scopHTc.length +
            scopbLTa.length +
            scopbLTw.length +
            scopbLTc.length +
            scopbITa.length +
            scopbITw.length +
            scopbITc.length +
            scopbMTa.length +
            scopbMTw.length +
            scopbMTc.length +
            scopbHTa.length +
            scopbHTw.length +
            scopbHTc.length !==
        0

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell component='th' scope='row'>
                    {optionsValid}
                </TableCell>
                <TableCell align='center' component='th' scope='row'>
                    {size[0]}
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={seerLT} name='Pdesignc' />
                    <StyledPreviewData data={seerLT} name='SEER' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={seerMT} name='Pdesignc' />
                    <StyledPreviewData data={seerMT} name='SEER' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={seprMT} name='Pdc_A' />
                    <StyledPreviewData data={seprMT} name='SEPR' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={seprHT} name='Pdc_A' />
                    <StyledPreviewData data={seprHT} name='SEPR' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopMTa} name='Pdesignh' />
                    <StyledPreviewData data={scopMTa} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopLTa} name='Pdesignh' />
                    <StyledPreviewData data={scopLTa} name='SCOP' />
                </TableCell>
                {/* <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopLTw} name='Pdesignh' />
                    <StyledPreviewData data={scopLTw} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopLTc} name='Pdesignh' />
                    <StyledPreviewData data={scopLTc} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopMTw} name='Pdesignh' />
                    <StyledPreviewData data={scopMTw} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopMTc} name='Pdesignh' />
                    <StyledPreviewData data={scopMTc} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopbLTa} name='Pdesignh' />
                    <StyledPreviewData data={scopbLTa} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopbLTw} name='Pdesignh' />
                    <StyledPreviewData data={scopbLTw} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopbLTc} name='Pdesignh' />
                    <StyledPreviewData data={scopbLTc} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopbMTa} name='Pdesignh' />
                    <StyledPreviewData data={scopbMTa} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopbMTw} name='Pdesignh' />
                    <StyledPreviewData data={scopbMTw} name='SCOP' />
                </TableCell>
                <TableCell component='th' align='center' scope='row'>
                    <StyledPreviewData data={scopbMTc} name='Pdesignh' />
                    <StyledPreviewData data={scopbMTc} name='SCOP' />
                </TableCell> */}
                <TableCell component='th' align='center' scope='row'>
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        disabled={!haveOUtput}
                        onClick={() => setOpen(!open)}>
                        <StorageIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                    }}
                    colSpan={20}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        {open && (
                            <Box margin={1}>
                                <Grid container justify='left' spacing={1}>
                                    {seerLT.length > 0 && <TableDetail calc={seerLT} name='SEER LT' />}
                                    {seerMT.length > 0 && <TableDetail calc={seerMT} name='SEER MT' />}
                                    {seprMT.length > 0 && <TableDetail calc={seprMT} name='SEPR MT' />}
                                    {seprHT.length > 0 && <TableDetail calc={seprHT} name='SEPR HT' />}
                                    {scopLTa.length > 0 && <TableDetail calc={scopLTa} name='SCOP LTa' />}
                                    {scopMTa.length > 0 && <TableDetail calc={scopMTa} name='SCOP MTa' />}
                                    {scopLTw.length > 0 && <TableDetail calc={scopLTw} name='SCOP LTw' />}
                                    {scopLTc.length > 0 && <TableDetail calc={scopLTc} name='SCOP LTc' />}
                                    {scopMTw.length > 0 && <TableDetail calc={scopMTw} name='SCOP MTw' />}
                                    {scopMTc.length > 0 && <TableDetail calc={scopMTc} name='SCOP MTc' />}
                                    {scopbLTa.length > 0 && <TableDetail calc={scopbLTa} name='SCOP bLTa' />}
                                    {scopbLTw.length > 0 && <TableDetail calc={scopbLTw} name='SCOP bLTw' />}
                                    {scopbLTc.length > 0 && <TableDetail calc={scopbLTc} name='SCOP bLTc' />}
                                    {scopbMTa.length > 0 && <TableDetail calc={scopbMTa} name='SCOP bMTc' />}
                                    {scopbMTw.length > 0 && <TableDetail calc={scopbMTw} name='SCOP bMTw' />}
                                    {scopbMTc.length > 0 && <TableDetail calc={scopbMTc} name='SCOP bMTc' />}
                                    {scopITa.length > 0 && <TableDetail calc={scopITa} name='SCOP ITa' />}
                                </Grid>
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default React.memo(Row)
