import React from 'react'
import styled from 'styled-components'

import useAddOptionDropDown from './useAddOptionDropDown'
import IconButton from '@material-ui/core/IconButton'
import Modal from '../../../Common/Modal'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

const StyledButton = styled(Button)`
    && {
        margin: 4px 6px;
        padding: 2px 6px;
    }
`

const AddOptionDropDown = ({
    Parameters,
    haveVariablePump,
    haveFixedPump,
    Ruleset,
    Model,
    type,
    onParameterClick,
    onRulesetDefined,
}) => {
    const { onAddParam, LoadOption, Loading, isOpen, setIsOpen, displayPumpType, options } = useAddOptionDropDown({
        Parameters,
        haveVariablePump,
        haveFixedPump,
        Ruleset,
        onRulesetDefined,
        Model,
        type,
        onParameterClick,
    })

    return (
        <>
            <Tooltip title={'Add ' + type} aria-label={'add-' + type}>
                <StyledButton disableElevation variant='contained' color='primary' onClick={() => LoadOption()}>
                    {/* <AddIcon color='primary' />  */} {type}
                </StyledButton>
            </Tooltip>

            {isOpen && (
                <Modal open={isOpen} handleClose={() => setIsOpen(false)} title={type + ' for ' + Model}>
                    {Loading && <div>Loading...</div>}
                    {options.map((o, index) => (
                        <ListItem dense button key={index} onClick={() => onAddParam(index)}>
                            {o.Description}
                            {displayPumpType(o.PumpType)}
                        </ListItem>
                    ))}
                </Modal>
            )}
        </>
    )
}

export default AddOptionDropDown
