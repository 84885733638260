import React from 'react'
import Modal from '../Common/Modal'
import styled from 'styled-components'
import DenseTable from './Table'

const Container = styled.div``

const StyledDenseTable = styled(DenseTable)`
    & {
        border: 2px solid #3f51b5;
        box-sizing: border-box;
    }
`

const detailModal = ({ handleClose, open, rows, title }) => {
    return (
        <>
            <Modal open={open} handleClose={handleClose} title={title}>
                <Container>
                    <StyledDenseTable rows={rows} />
                </Container>
            </Modal>
        </>
    )
}

export default detailModal
