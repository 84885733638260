//round

const scop = [
    { Pdesignh: 1 },
    { QHE: 0 },
    { ETAS_SCOP: 0 },
    { SCOP: 2 },
    { SCOPon: 2 },
    { SCOPnet: 2 },
    { Lwi: 1 },
    { Lwo: 1 },
    { TOL: 1 },
    { WTOL: 0 },
    { OATA: 0 },
    { OATB: 0 },
    { OATC: 0 },
    { OATD: 0 },
    { OATE: 0 },
    { OATF: 0 },
    { Pdh_A: 1 },
    { Pdh_B: 1 },
    { Pdh_C: 1 },
    { Pdh_D: 1 },
    { Pdh_E: 1 },
    { Pdh_F: 1 },
    { COPd_A: 2 },
    { COPd_B: 2 },
    { COPd_C: 2 },
    { COPd_D: 2 },
    { COPd_E: 2 },
    { COPd_F: 2 },
    { Cdh_A: 2 },
    { Cdh_B: 2 },
    { Cdh_C: 2 },
    { Cdh_D: 2 },
    { Cdh_E: 2 },
    { Cdh_F: 2 },
    { Cdh_G: 2 },
    { POFF: 0 },
    { PTO: 0 },
    { PSB: 0 },
    { PCK: 0 },
    { Psup: 0 },
    { OutWaterFlow: 2 },
    { RefrCharge: 2 },
    { RefrC02: 2 },
    { RefrCharge: 2 },
    { RefrC02: 2 },
    { AirFlow: 2 },
    { EvapDP: 1 },
    { EvapESP: 1 },
    { ConDP: 1 },
    { CondESP: 1 }
]

//round

const seer = [
    { Pdesignc: 1 },
    { ETAS: 0 },
    { SEER: 2 },
    { SEERON: 2 },
    { Lwi: 1 },
    { Lwo: 1 },
    { TOL: 1 },
    { OATA: 0 },
    { OATB: 0 },
    { OATC: 0 },
    { OATD: 0 },
    { Pdc_A: 1 },
    { Pdc_B: 1 },
    { Pdc_C: 1 },
    { Pdc_D: 1 },
    { EERd_A: 2 },
    { EERd_B: 2 },
    { EERd_C: 2 },
    { EERd_D: 2 },
    { Cdc_A: 2 },
    { Cdc_B: 2 },
    { Cdc_C: 2 },
    { Cdc_D: 2 },
    { POFF: 0 },
    { PTO: 0 },
    { PSB: 0 },
    { PCK: 0 },
    { WaterFlow: 2 },
    { RefrCharge: 2 },
    { RefrC02: 2 },
    { AirFlow: 2 },
    { EvapDP: 1 },
    { EvapESP: 1 },
    { ConDP: 1 },
    { CondESP: 1 }
]

//round

const sepr = [
    { T: 0 },
    { SEPR: 2 },
    { Q: 0 },
    { OATA: 0 },
    { OATB: 0 },
    { OATC: 0 },
    { OATD: 0 },
    { Pdc_A: 1 },
    { Pdc_B: 1 },
    { Pdc_C: 1 },
    { Pdc_D: 1 },
    { Dd_A: 1 },
    { Dd_B: 1 },
    { Dd_C: 1 },
    { Dd_D: 1 },
    { EERd_A: 2 },
    { EERd_B: 2 },
    { EERd_C: 2 },
    { EERd_D: 2 },
    { Cdc_A: 2 },
    { Cdc_B: 2 },
    { Cdc_C: 2 },
    { Cdc_D: 2 },
    { RefrCharge: 2 },
    { RefrC02: 2 },
    { EvapDP: 1 },
    { EvapESP: 1 },
    { ConDP: 1 },
    { CondESP: 1 }
]

const allRoundedOutput = [...seer, ...sepr, ...scop]
const findRoundedOnAllOutput = (name) => {
    try {
        return allRoundedOutput?.filter((el) => Object.keys(el)[0] === name)[0][name]
    } catch {
        return 2
    }
}

export { scop, seer, sepr, allRoundedOutput, findRoundedOnAllOutput }
