import React from 'react'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useClipboard from './useClipboard'
import IconButton from '@material-ui/core/IconButton'
import { findRoundedOnAllOutput } from '../../../../Assets/mappingData'
import { sortByName } from '../../../../Assets/Helpers'

const StyledTableDetail = styled(Table)`
    &&& {
        border: none;
        thead th,
        tbody th,
        tbody tr {
            border: none;
            padding: 2px 5px;
        }
        tr:nth-child(odd) {
            background: #f5f5f5;
        }
    }
`

const StyledTableCellDetail = styled(TableCell)``
const StyledIconButton = styled(IconButton)`
    && {
        font-size: 14px;
        margin: 0;
        padding: 0px;
        border-radius: 0;
        width: 100%;
        :hover {
            color: #1891f6;
            background: none;
            background-color: none;
        }
    }
`

const StyledTypography = styled(Typography)`
    text-align: center;
`
const StyledTableBody = styled(TableBody)``

const Container = styled.div`
    color: #6f6e6ede;
    color: #414141;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const StyledGrid = styled(Grid)`
    && {
        margin: 5px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
    }
`

const TableDetail = ({ calc, name: title }) => {
    const [, setClipboard] = useClipboard(2000)

    return (
        <StyledGrid item>
            <StyledTypography gutterBottom component='div'>
                {title}
            </StyledTypography>
            <StyledTypography variant='overline' display='block' gutterBottom>
                {calc[0].time && <>{calc[0].time} min.</>}
            </StyledTypography>
            <StyledTypography variant='overline' display='block' gutterBottom>
                {calc[0].date && <>{new Date(calc[0].date).toLocaleString()}</>}
            </StyledTypography>

            <StyledTableDetail size='small'>
                <StyledTableBody>
                    {calc.sort(sortByName).map(({ name, value, unit }, index) => (
                        <TableRow key={'row' + title + name + index}>
                            <StyledTableCellDetail component='th' scope='row' key={'cell' + title + name + index}>
                                <StyledIconButton onClick={() => setClipboard(value)} disabled={false}>
                                    <Container>
                                        <div>{name}:</div>
                                        <div>
                                            <b>{parseFloat(value).toFixed(findRoundedOnAllOutput(name))}</b> {unit}
                                        </div>
                                    </Container>
                                </StyledIconButton>
                            </StyledTableCellDetail>
                        </TableRow>
                    ))}
                </StyledTableBody>
            </StyledTableDetail>
        </StyledGrid>
    )
}

export default TableDetail
