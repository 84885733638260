const { GetAllProduct } = require('../Assets/Interfaces/ProductInterface')
import { FilterAndReconfigure } from '@carrier/workflowui-globalfunctions'
import { rulesAppServices } from '../Environments/environment'
import Constants from '../Assets/Constants'

const fetchInitialProduct = async () => {
    // Grab products from ecodesign
    const productList = await GetAllProduct()
    // Grab models from rules
    const ruleResult = await FilterAndReconfigure(rulesAppServices, 'PCU_EMEA', 'SMART', null, ['Sel_sModel'], [], null)
    // Add RangeIndex to product name
    return productList?.map((product) => ({
        name: product.productName,
        rangeIndexDB: product.rangeIndex,
        rangeIndexRules:
            ruleResult.VariableDomains.find(({ Name }) => Name === 'Sel_sModel').Values.find(
                (v) => v.Value === product.productName
            )?.Attributes.RangeIndex === ''
                ? Constants.DEFAULT_RANGE_INDEX
                : ruleResult.VariableDomains.find(({ Name }) => Name === 'Sel_sModel').Values.find(
                      (v) => v.Value === product.productName
                  )?.Attributes.RangeIndex,
        calcStatus: product.calcStatus,
        userName: product.userName,
    }))
}

export { fetchInitialProduct }
