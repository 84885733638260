import { useState, useContext } from 'react'
import { saveData } from '../../../../Assets/Interfaces/ValidationInterface'
import { updateCandidatesRangeIndex } from '../../../../Assets/Interfaces/CandidatesInterface'
import { GlobalContext } from './../../../../store/index'

export default function useValidation(product, data) {
    const [state] = useContext(GlobalContext)
    const statusEnum = {
        Validated: 'Validated',
        InProgress: 'In_Progress',
        Error: 'Error',
        NotValidated: 'Validate',
    }

    const [validationStatus, setValidationStatus] = useState(statusEnum[product.calcStatus])
    const [validationProgress, setValidationProgress] = useState(0)

    // Accepts the array and key
    const groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            ; (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result
        }, {}) // empty object is the initial value for result object
    }

    const trackValidation = async (progress = 0) => {
        if (progress === -1) {
            setValidationStatus(statusEnum.Error)
            setValidationProgress(0)
        } else {
            setValidationStatus(progress !== 100 ? statusEnum.InProgress : statusEnum.Validated)
            setValidationProgress(progress)
        }
    }

    const updateModelRangeIndex = async (model, rangeIndex) => {
        return updateCandidatesRangeIndex(model, rangeIndex, state.user.name)
            .then((res) => {
                setValidationStatus(statusEnum.NotValidated)
                return res
            })
            .catch((err) => {
                throw err
            })
    }

    return { statusEnum, trackValidation, updateModelRangeIndex, validationStatus, validationProgress }
}
