import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import styled from 'styled-components'

const StyledTableContainer = styled(TableContainer)`
    margin: 10px;
`

const StyledStatus = styled.span`
    color: ${({ isSuccess }) => (isSuccess ? 'green' : 'red')};
`

export default function StatusTable({ label, data }) {
    return (
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant='head' colSpan={4}>
                            {label}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell size='small'>mode</TableCell>
                        <TableCell size='small'>option</TableCell>
                        <TableCell size='small' align='right'>
                            status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell size='small'>{row.mode}</TableCell>
                            <TableCell size='small'>{row.option.join(' ')}</TableCell>
                            <TableCell size='small' align='right'>
                                <StyledStatus isSuccess={row.status === 'Imported'}>{row.status}</StyledStatus>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}
