import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import useExcelParse from './useExcelParse'
import ImportedTable from './ImportedTable'
import StatusTable from './StatusTable'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import { GlobalContext } from '../../store'

const Container = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
`

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function BasicTabs({ data, status, nbPages }) {
    const [state] = useContext(GlobalContext)
    const [value, setValue] = useState(0)
    const [statusData, setStatusData] = useState([])
    const styles = {
        customTypography: {
            fontSize: '15px',
            color: '#5b5d5f',
            fontWeight: '500',
        },
    }
    useEffect(() => {
        if (state.socket)
            state.socket.on('import-smartselection', (dataSocket) => {
                dataSocket && setStatusData((prev) => [...prev, dataSocket])
            })
    }, [state.socket])

    const handleChange = (_, newValue) => {
        setValue(newValue)
    }
    const selecteMode = data[0]?.mode || null
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {selecteMode && <Typography style={styles.customTypography}>The used mode : {selecteMode}</Typography>}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'>
                    {status && (
                        <Tab
                            label={`Status ${nbPages ? `${Math.round((statusData.length / nbPages) * 100)} %` : ''}`}
                        />
                    )}
                    {data.length > 0 && data.map((el, index) => <Tab key={index} label={el.label} />)}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {statusData.map((el) => (
                    <StatusTable label={el.label} data={el.data} />
                ))}
            </TabPanel>
            {data.length > 0 &&
                data.map((el, index) => (
                    <TabPanel value={value} index={index + 1}>
                        <ImportedTable data={el.data} mode={el.mode} />
                    </TabPanel>
                ))}
        </Box>
    )
}

function SmartSelection() {
    const { handleChange, handleSubmit, excelData, importResult, loading, excelNbPages } = useExcelParse()

    return (
        <Container>
            <div>
                <input multiple onChange={handleChange} type='file' />
                <Button
                    disabled={!excelData.length || loading}
                    onClick={handleSubmit}
                    variant='contained'
                    endIcon={loading && <HourglassEmptyIcon />}>
                    Send {excelData?.length} pages
                </Button>
            </div>
            <BasicTabs data={excelData} status={importResult} nbPages={excelNbPages} />
        </Container>
    )
}

export default SmartSelection
