import React, { useState, useEffect } from 'react'
import Modal from '../../Common/Modal'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { GetAllCalcTpe } from '../../../Assets/Interfaces/RulesEngineInterface'

const StyledInputOption = styled(Button)`
    font-weight: 800;
    box-shadow: none;
    color: white;
`
const generateStatusOptions = (currentStatus) => {
    const statusList = ['pending', 'success', 'error', 'empty']

    return statusList.map((status) => (
        <option selected={status === currentStatus} value={status}>
            {status}
        </option>
    ))
}

const generateCalculationOptions = (calculationList, calcType) => (
    <>
        {calculationList?.map((calcul, index) => (
            <option selected={calcul === calcType} value={calcul} key={index}>
                {calcul}
            </option>
        ))}
    </>
)

const FilterModal = ({
    handleSubmit,
    handleClose,
    handleChange,
    handleReset,
    open,
    filter,
    toggleOptionStrict,
    model,
}) => {
    const [calculationList, setCalculationList] = useState([])
    useEffect(() => {
        GetAllCalcTpe(model).then((result) => {
            setCalculationList(result.filter(Boolean))
        })
    }, [])
    const [selectedCalculation, setSelectedCalculation] = useState({ value: '', name: '' })

    const { size, option, optionStrict, calcType, calcTypeValue } = filter
    return (
        <>
            <Modal
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                title='Filter candidates'>
                <TextField
                    label='Select size'
                    margin='normal'
                    name='size'
                    value={size}
                    helperText='Multiple sizes can be selected by separating them with spaces'
                    onChange={handleChange}
                    variant='outlined'
                />
                <TextField
                    label='Select Option'
                    value={option}
                    InputProps={{
                        endAdornment: (
                            <div onClick={toggleOptionStrict}>
                                {optionStrict ? (
                                    <StyledInputOption variant='contained'>AND</StyledInputOption>
                                ) : (
                                    <StyledInputOption variant='contained'>OR</StyledInputOption>
                                )}
                            </div>
                        ),
                    }}
                    name='option'
                    onChange={handleChange}
                    helperText="Multiple option can be selected by separating them with spaces and option false can be selected by suffixing with ':false'. Click on the icon in the input to change the type of selection"
                    margin='normal'
                    variant='outlined'
                />

                <FormControl variant='filled' className='control'>
                    <InputLabel htmlFor='status-seprMT'>Select a calculation type</InputLabel>
                    <Select
                        defaultValue={''}
                        native
                        onChange={handleChange}
                        inputProps={{
                            name: 'calcType',
                            id: `status-calcType`,
                        }}>
                        {<option selected={calcType === ''}>All</option>}
                        {generateCalculationOptions(calculationList, calcType)}
                    </Select>
                </FormControl>
                <FormControl variant='filled' className='control'>
                    <InputLabel htmlFor={`status-calcTypeValue`}>Select a calculation state</InputLabel>
                    <Select
                        native
                        defaultValue={''}
                        onChange={handleChange}
                        inputProps={{
                            name: 'calcTypeValue',
                            id: `status-calcTypeValue`,
                        }}>
                        <option selected={calcTypeValue === ''}>All</option>
                        {generateStatusOptions(calcTypeValue)}
                    </Select>
                </FormControl>
            </Modal>
        </>
    )
}

export default FilterModal
