import React, { useContext } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import useValidation from './useValidation'
import { SuccessIcon, FailIcon, PlayIcon } from '../../../SvgImages'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ManualPerformances from '../../../ManualPerformances'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import generateExcelFile from './generateExcelFile'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Box, Typography } from '@material-ui/core'
import { GlobalContext } from '../../../../store'
import Constants from '../../../../Assets/Constants'
import Avatar from '../../../Common/Avatar'

const StyledCardHeader = styled(CardHeader)`
    word-break: break-word;
    display: flex;
    flew-wrap: wrap;
    align-content: center;
    min-height: 110px;
`

const StyledCardActions = styled(CardActions)`
    justify-content: center;
    align-items: center;
    display: flex;
`

const StyledCard = styled(Card)`
    && {
        border: none;
        border-radius: 0;

        box-shadow: unset;
    }
`

const StyledButtonGroup = styled(ButtonGroup)`
    margin: 5px;
    border-radius: 0;
`

const StyledFormControl = styled(FormControl)`
    width: 150px;
    color: ${Constants.COLORS.enable};
    & > div {
        border-radius: 0;
    }
`
const StyledSelect = styled(Select)`
    & > div {
        color: ${Constants.COLORS.enable};
        border: 1px solid rgba(63, 81, 181, 0.5);
        border-radius: 0;
        font-size: 0.8125rem;
    }
`

const StyledButton = styled(Button)`
    && {
        border-radius: 0;
    }
`

const StyledMenuItem = styled(MenuItem)`
    && {
        font-size: 0.8125rem;
    }
`

const StyledCircularProgress = styled(CircularProgress)`
    && {
        color: ${Constants.COLORS.inprogress};
        margin-right: 5px;
    }
`

const StyledProgressTypography = styled(Typography)`
    && {
        font-size: 0.5em;
    }
`

const TableHeader = ({ product, onClick, data, calcTypes }) => {
    // Hooks
    const [state] = useContext(GlobalContext)
    const navigate = useNavigate()
    const { statusEnum, updateModelRangeIndex, trackValidation, validationProgress, validationStatus } = useValidation(
        product,
        data
    )

    // State
    const [open, setOpen] = React.useState(false)
    const [dropDownValue, setDropDownValue] = React.useState('')
    const [innerProduct, setInnerProduct] = React.useState(product)
    const [candidatesUpdated, setCandidatesUpdated] = React.useState(undefined)

    // Helpers
    const statusColors = {
        Validated: Constants.COLORS.validated,
        In_Progress: Constants.COLORS.inprogress,
        Error: Constants.COLORS.error,
        Validate: data.length ? Constants.COLORS.enable : Constants.COLORS.disable,
    }
    const statusIcon = {
        Validated: () => <SuccessIcon Color={statusColors.Validated}></SuccessIcon>,
        In_Progress: () => (
            <Box position='relative' display='inline-flex'>
                <StyledCircularProgress size={20} variant='determinate' value={validationProgress} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position='absolute'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'>
                    <StyledProgressTypography variant='caption' noWrap component='div'>{`${Math.round(
                        validationProgress
                    )}%`}</StyledProgressTypography>
                </Box>
            </Box>
        ),
        Error: () => <FailIcon Color={statusColors.Validation_Error}></FailIcon>,
        Validate: () => <PlayIcon Color={statusColors.Validate} height='10'></PlayIcon>,
        Validation_Hold: () => <NotInterestedIcon></NotInterestedIcon>,
    }

    // Handler functions
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleChangeValue = (event) => {
        setDropDownValue(event.target.value)
    }

    const handleModelRangeIndexUpdate = () => {
        updateModelRangeIndex(innerProduct.name, innerProduct.rangeIndexRules).then((res) => {
            setInnerProduct({ ...innerProduct, rangeIndexDB: innerProduct.rangeIndexRules })
            setCandidatesUpdated(res.rowsAffected)
        })
    }
    const needUpgrade = innerProduct.rangeIndexDB !== innerProduct.rangeIndexRules

    React.useEffect(() => {
        if (state.socket) {
            state.socket.on('ValidateLoading', (model, progress) => {
                if (model === innerProduct.name) trackValidation(progress)
            })
        }
    }, [state])

    return (
        <>
            {open && (
                <Dialog open={open} keepMounted onClose={handleClose} aria-describedby='alert-dialog-slide-description'>
                    <DialogTitle>Import Performances from Excel file</DialogTitle>
                    <DialogContent>
                        <ManualPerformances model={innerProduct.name} calcTypes={calcTypes} />
                    </DialogContent>
                </Dialog>
            )}
            <StyledCard variant='outlined'>
                <StyledCardHeader
                    action={
                        <StyledButtonGroup size='small' aria-label='small outlined button group'>
                            <Button
                                disabled={!data.length || needUpgrade || validationStatus === statusEnum.InProgress}
                                onClick={() =>
                                    state.socket.emit('Validate', {
                                        model: product.name,
                                        username: state.user.name,
                                        rangeIndexDB: product.rangeIndexDB,
                                    })
                                }
                                style={{
                                    color: needUpgrade
                                        ? statusColors.Validation_In_Progress
                                        : statusColors[validationStatus],
                                    borderColor: needUpgrade
                                        ? statusColors.Validation_In_Progress
                                        : statusColors[validationStatus],
                                }}>
                                {needUpgrade ? statusIcon.Validation_Hold() : statusIcon[validationStatus]()}
                                {validationStatus}
                            </Button>
                            <Button
                                style={{
                                    color: statusColors[validationStatus],
                                    borderColor: statusColors[validationStatus],
                                }}
                                disabled={!data.length}
                                onClick={() => generateExcelFile({ model: innerProduct.name, calcTypes, data })}>
                                {' '}
                                <GetAppIcon></GetAppIcon>DOWNLOAD DATA
                            </Button>
                        </StyledButtonGroup>
                    }
                    title={innerProduct.name}
                    subheader={
                        needUpgrade ? (
                            <Button variant='outlined' onClick={() => handleModelRangeIndexUpdate()}>
                                <Avatar color={Constants.COLORS.inprogress}>{innerProduct.rangeIndexDB}</Avatar>
                                <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                <Avatar color={Constants.COLORS.inprogress}>{innerProduct.rangeIndexRules}</Avatar>
                            </Button>
                        ) : (
                            <Avatar color={statusColors[validationStatus]}>{innerProduct.rangeIndexDB}</Avatar>
                        )
                    }
                />
                <StyledCardActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    <StyledButtonGroup size='small' color='primary'>
                        <StyledButton onClick={onClick}>Performances</StyledButton>
                        <StyledFormControl>
                            <StyledSelect
                                labelId='options-label'
                                id='options-label'
                                defaultValue='calculation'
                                aria-label='small outlined button group'
                                onChange={handleChangeValue}>
                                <StyledMenuItem
                                    onClick={() => navigate(`/runCalculation/${innerProduct.name}`)}
                                    value='calculation'
                                    disabled={needUpgrade}
                                    selected={true}>
                                    CALCULATE
                                </StyledMenuItem>
                                <StyledMenuItem onClick={handleClickOpen} value='import' disabled={needUpgrade}>
                                    IMPORT FILE
                                </StyledMenuItem>
                            </StyledSelect>
                        </StyledFormControl>
                    </StyledButtonGroup>
                </StyledCardActions>
                {candidatesUpdated ? (
                    <Alert severity='info'>{candidatesUpdated} candidates have been updated</Alert>
                ) : null}
                {innerProduct.userName ? <Alert severity='info'>Last update by {innerProduct.userName}</Alert> : null}
            </StyledCard>
        </>
    )
}

export default TableHeader
