import { Avatar } from '@material-ui/core'
import styled from 'styled-components'
import Constants from '../../../Assets/Constants'

const StyledAvatar = styled(Avatar)`
    && {
        background-color: ${(props) => (props.color ? props.color : Constants.COLORS.enable)};
    }
`

export default StyledAvatar
