import { FilterAndReconfigure } from '@carrier/workflowui-globalfunctions'
import { rulesAppServices } from '../../Environments/environment'
import Constants from '../Constants'
var xml2js = require('xml2js')

async function UpdateCandidateXML(Model) {
    let Obj = {
        CandidateSelectionDatabase: {
            ProductLine: {
                $: { name: 'PCU_EMEA' },
                Model: {
                    $: { name: Model.Ruleset },
                    Section: {
                        $: { name: 'InputCandList' },
                        OutputVariables: { Variable: [] },
                        SearchVariables: { Variable: [] },
                        LookupVariables: { Variable: [] },
                        ExcludedValues: { Variable: [] },
                    },
                },
            },
        },
    }
    Model.Parameters.forEach((p) => {
        Obj.CandidateSelectionDatabase.ProductLine.Model.Section.OutputVariables.Variable.push({ $: { name: p.Name } })
        Obj.CandidateSelectionDatabase.ProductLine.Model.Section.SearchVariables.Variable.push({ $: { name: p.Name } })
    })
    let builder = new xml2js.Builder()
    const xml = builder.buildObject(Obj)
    const res = await fetch(rulesAppServices + 'CandidateSelectionDB_Update', {
        method: 'POST',
        headers: Constants.XMLApiHeader(),
        body: xml,
    })
    const jsonResponse = await res.json()
    return jsonResponse
}

async function GetRuleset(ModelName) {
    let Assignment = [{ Name: 'Sel_sModel', Value: ModelName }]
    let SelectionRulesJSON = await FilterAndReconfigure(
        rulesAppServices,
        'PCU_EMEA',
        'Selection',
        null,
        ['Sel_sConfRuleset'],
        Assignment,
        ''
    )
    return SelectionRulesJSON.VariableDomains.find((element) => element.Name === 'Sel_sConfRuleset').Value
}

async function GetAllCalcTpe(ModelName) {
    let Assignment = [{ Name: 'Sel_sModel', Value: ModelName }]
    let SelectionRulesJSON = await FilterAndReconfigure(
        rulesAppServices,
        'PCU_EMEA',
        'SMART',
        null,
        ['Report_sTDSCalculationType'],
        Assignment,
        ''
    )

    const calculationType = SelectionRulesJSON.VariableDomains.find(
        ({ Name }) => Name === 'Report_sTDSCalculationType'
    ).Values.map(({ State, Attributes }) => {
        if (Attributes?.Description && State === 1) return Attributes.Description
    })
    return calculationType
}

async function FindAllSolutions(ruleset, model) {
    const res = await fetch(rulesAppServices + 'FindAllSolutions', {
        method: 'POST',
        headers: Constants.ApiHeader(),
        body: JSON.stringify({
            SelectedProductLine: 'PCU_EMEA',
            SelectedModel: ruleset,
            SalesAssignment: [{ ...Constants.getDefaultAssigmment() }, ...{ Name: 'Sel_sModel', Value: model }],
            HighestPriorityProperties: [],
            LowestPriorityProperties: [],
            Tags: null,
            TargetProperties: null,
        }),
    })
    const jsonResponse = await res.json()
    return jsonResponse
}

async function FindAllSolutionsBySize(ruleset, model, size) {
    const resPromise = await fetch(rulesAppServices + 'FindAllSolutions', {
        method: 'POST',
        headers: Constants.ApiHeader(),
        body: JSON.stringify({
            SelectedProductLine: 'PCU_EMEA',
            SelectedModel: ruleset,
            SalesAssignment: [
                { Name: 'Sel_sModel', Value: model },
                { Name: 'Sel_sSize', Value: size },
            ],
            HighestPriorityProperties: [],
            LowestPriorityProperties: [],
            Tags: null,
            TargetProperties: null,
        }),
    })
    const res = await resPromise.json()
    return res.Sections[0].Candidates
}

async function getAllSizeForModel(model) {
    let Assignment = [{ Name: 'Sel_sModel', Value: model }]
    let SelectionRulesJSON = await FilterAndReconfigure(
        rulesAppServices,
        'PCU_EMEA',
        'SMART',
        null,
        'Sel_sModel',
        Assignment,
        ''
    )

    return SelectionRulesJSON.VariableDomains.find(({ Name }) => Name === 'Sel_sSize')
        .Values.filter(({ State }) => State === 1)
        .map(({ Value }) => Value)
}

export { UpdateCandidateXML, FindAllSolutions, GetRuleset, getAllSizeForModel, FindAllSolutionsBySize, GetAllCalcTpe }
