import actionContext from './actions'

const reducer = (state, action) => {
    switch (action.type) {
        case actionContext.CHANGE_UI_CALCULATION_RUN:
            return {
                ...state,
                ui: { calculationRun: action.data },
            }
        case actionContext.CHANGE_USER_NAME:
            return {
                ...state,
                user: { name: action.data },
            }
        case actionContext.FETCH_PRODUCTS_AVAILABLE:
            return {
                ...state,
                products: action.data,
            }
        case actionContext.INITIALIZE_WEBSOCKET:
            return {
                ...state,
                socket: action.data,
            }

        default:
            throw new Error()
    }
}

export default reducer
