import * as React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

export default function ImportedTable({ data, mode }) {
    console.log('ImportedTable ', { mode })

    if (mode === 'pressure')
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell size='small'>Size</TableCell>
                            <TableCell size='small'>Options</TableCell>
                            <TableCell size='small'>Condition</TableCell>
                            <TableCell size='small'>Attribute</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell size='small'>{row.size}</TableCell>
                                <TableCell size='small'>{row.options}</TableCell>
                                <TableCell size='small'>{row.condition}</TableCell>
                                <TableCell size='small'>{row.attr}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell size='small'>size</TableCell>
                        <TableCell size='small'>options</TableCell>
                        <TableCell size='small'>indoor</TableCell>
                        <TableCell size='small'>outdoor</TableCell>
                        <TableCell size='small'>attribute</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell size='small'>{row.size}</TableCell>
                            <TableCell size='small'>{row.options}</TableCell>
                            <TableCell size='small'>{row.condition}</TableCell>
                            <TableCell size='small'>{row.condition2}</TableCell>
                            <TableCell size='small'>{row.attr}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
