import React from 'react'
import Modal from '../../Common/Modal'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Container = styled.div`
    margin: 16px;
`

const ErrorModal = ({ handleClose, open, message }) => {
    return (
        <>
            <Modal noTitle={true} open={open} handleClose={handleClose} title='error'>
                <Container>
                    <Typography variant='subtitle1' gutterBottom>
                        {message}
                    </Typography>
                </Container>
            </Modal>
        </>
    )
}

export default ErrorModal
