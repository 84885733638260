import React from 'react'
import useCandidatesPopup from './useCandidatesPopup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const SyledChip = styled.div`
    background: ${({ value }) => (value ? ' #71ab71' : '#f16969')};
    color: white;
    width: 100%;
    height: 10px;
    display: inline-block;
    text-align: center;
`

const SyledTableCell = styled(TableCell)`
    padding: 2px 8px;
    margin: 1px;
    font-size: 13px;
    display: inline-block;
    text-align: center;
`

const StyledTableContainer = styled(TableContainer)`
    border: none;
    padding: none;
    display: block;
    overflow-y: auto;
    height: 80vh;
    margin: 20px 0;
    tbody {
        overflow-y: auto;
    }
`

const Message = styled.div`
    margin: 10px;
    margin-bottom: 0;
    color: #0000008a;
`

const StyledTable = styled(Table)`
    border: none;
`

const StyledContainerCircular = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

function CircularProgressWithLabel(props) {
    return (
        <Box position='relative' display='inline-flex'>
            <CircularProgress variant='determinate' {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'>
                <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    )
}

const GetValueCell = (Value, i) => {
    switch (Value) {
        case 'TRUE':
            return (
                <SyledTableCell>
                    <SyledChip value={true} key={i} />
                </SyledTableCell>
            )
        case 'FALSE':
            return (
                <SyledTableCell>
                    <SyledChip value={false} key={i} />
                </SyledTableCell>
            )
        default:
            return <SyledTableCell key={i}>{Value}</SyledTableCell>
    }
}

const CandidatesPopup = (props) => {
    const { ProgressPercent, Candidates, LoadingMessage, GetParameterDescription } = useCandidatesPopup(props)

    return (
        <>
            {Candidates.length === 0 && <Message>{LoadingMessage}</Message>}

            {ProgressPercent !== null && (
                <StyledContainerCircular>
                    <CircularProgressWithLabel value={ProgressPercent} />
                </StyledContainerCircular>
            )}
            {Candidates.length > 0 && (
                <>
                    <StyledTableContainer component={Paper}>
                        <StyledTable size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow>
                                    {Candidates &&
                                        Object.keys(Candidates[0].Assignments).map((PropName, i) => (
                                            <SyledTableCell key={'tablehead-parameter' + i}>
                                                {GetParameterDescription(PropName)}
                                            </SyledTableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Candidates &&
                                    Candidates.map((Candidate, index) => (
                                        <TableRow key={index}>
                                            {Object.keys(Candidate.Assignments).map((PropName, i) =>
                                                GetValueCell(Candidate.Assignments[PropName], i)
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
                </>
            )}
        </>
    )
}

export default CandidatesPopup
