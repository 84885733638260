import { GetStats } from '../../../../Assets/Interfaces/ProductInterface'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import CircularBar from '../../../Common/CircularBar'
import FilledBar from '../../../Common/FilledBar'

const Container = styled.div`
    padding: 20px 30px;
    width: 50%;
`

const ContainerLeft = styled.div`
    padding: 20px 30px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StatsContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const Title = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 50px;
    color: #b9b9b9;
    z-index: 1;
    font-size: 10px;

    span {
        margin-right: 5px;
    }
`

const Stats = ({ name }) => {
    const [stat, setStat] = useState([])
    const [complete, setComplete] = useState(null)
    const [missing, setMissing] = useState(null)
    const [failing, setFailing] = useState(null)

    useEffect(() => {
        GetStats(name).then((res) => setStat(res))
    }, [])

    useEffect(() => {
        if (stat.length) {
            const totalCompleted = stat
                .map(({ total, success, unable }) => Math.round((unable / total + success / total) * 100))
                .reduce((acc, curr) => {
                    acc = curr + acc
                    return acc
                }, 0)

            const totalMissing = stat
                .map(({ total, success, unable, fail }) =>
                    Math.round(((total - success - unable - fail) / total) * 100)
                )
                .reduce((acc, curr) => {
                    acc = curr + acc
                    return acc
                }, 0)

            const totalFailing = stat
                .map(({ total, fail }) => Math.round((fail / total) * 100))
                .reduce((acc, curr) => {
                    acc = curr + acc
                    return acc
                }, 0)

            setComplete(totalCompleted / stat.length)
            setMissing(totalMissing / stat.length)
            setFailing(totalFailing / stat.length)
        }
    }, [stat])

    return (
        <StatsContainer>
            <ContainerLeft>
                <CircularBar
                    description='Calculation with success and unable '
                    title='complete'
                    color='#71ab71'
                    value={complete}
                />
                <CircularBar description='Calculation not launched' title='missing' color='#e0e0de' value={missing} />
                <CircularBar description='calculcation with error ' title='failing' color='#f16969' value={failing} />
            </ContainerLeft>
            <Container>
                {stat
                    ? stat.map(({ fail, total, unable, success, calcType, lastUpdate, oldUpdate }) => {
                          return (
                              <>
                                  <FilledBar
                                      name={calcType}
                                      fail={Math.round((fail / total) * 100)}
                                      success={Math.round((success / total) * 100)}
                                      unable={Math.round((unable / total) * 100)}
                                  />
                                  <div>
                                      {lastUpdate && (
                                          <Title>
                                              <div>
                                                  <span>oldest: </span>
                                                  <b>{oldUpdate && new Date(+oldUpdate).toLocaleString()}</b>
                                              </div>
                                              <div>
                                                  <span>lastest: </span>
                                                  <b>{lastUpdate && new Date(+lastUpdate).toLocaleString()}</b>
                                              </div>
                                          </Title>
                                      )}
                                  </div>
                              </>
                          )
                      })
                    : null}
            </Container>
        </StatsContainer>
    )
}

export default React.memo(Stats)
