import React, { useEffect, useState, useContext } from 'react'
import { GetAllProduct, GetStats } from '../../Assets/Interfaces/ProductInterface'
import styled from 'styled-components'
//import Stats from './../Tabs/Tab 3 Display Ecodesign data/TableDisplayData/Stats'
import CircularBar from '../Common/CircularBar'
import FilledBar from '../Common/FilledBar'
import Loader from '../Common/Loader'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Avatar } from '@material-ui/core'
import { GlobalContext } from '../../store'

const Container = styled.div`
    padding: 10px;
`

const ContainerLeft = styled.div`
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
`

const StatsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledCircularBar = styled(CircularBar)`
    & {
        margin: 0 5px;
    }
`

const StyledTypography = styled.div`
    color: #828181;
    text-align: center;
`

const Title = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 50px;
    color: #b9b9b9;
    z-index: 1;
    font-size: 10px;

    span {
        margin-right: 5px;
    }
`

const StyledPaper = styled(Paper)`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 2px;
`

const fetchInitialProduct = async () => {
    const productList = await GetAllProduct()

    return productList
}

const fetchStatsProduct = async (model) => {
    const resStat = await GetStats(model)
    return resStat
}

const Stats = ({ name, stats = null }) => {
    const [stat, setStat] = useState(stats)
    const [complete, setComplete] = useState(null)
    const [missing, setMissing] = useState(null)
    const [failing, setFailing] = useState(null)

    useEffect(() => {
        if (!stats) GetStats(name).then((res) => setStat(res))
    }, [])

    useEffect(() => {
        if (stat.length) {
            const totalCompleted = stat
                .map(({ total, success, unable }) => Math.round((unable / total + success / total) * 100))
                .reduce((acc, curr) => {
                    acc = curr + acc
                    return acc
                }, 0)

            const totalMissing = stat
                .map(({ total, success, unable, fail }) =>
                    Math.round(((total - success - unable - fail) / total) * 100)
                )
                .reduce((acc, curr) => {
                    acc = curr + acc
                    return acc
                }, 0)

            const totalFailing = stat
                .map(({ total, fail }) => Math.round((fail / total) * 100))
                .reduce((acc, curr) => {
                    acc = curr + acc
                    return acc
                }, 0)

            setComplete(totalCompleted / stat.length)
            setMissing(totalMissing / stat.length)
            setFailing(totalFailing / stat.length)
        }
    }, [stat])

    return (
        <StatsContainer>
            <ContainerLeft>
                <StyledCircularBar color='#71ab71' value={complete} thickness={8} size={80} />
                <StyledCircularBar color='#e0e0de' value={missing} thickness={8} size={80} />
                <StyledCircularBar color='#f16969' value={failing} thickness={8} size={80} />
            </ContainerLeft>
            <Container>
                {stat
                    ? stat.map(({ fail, total, unable, success, calcType, lastUpdate, oldUpdate }) => {
                          return (
                              <>
                                  <FilledBar
                                      height={15}
                                      breakNumber={14}
                                      name={calcType}
                                      fail={Math.round((fail / total) * 100)}
                                      success={Math.round((success / total) * 100)}
                                      unable={Math.round((unable / total) * 100)}
                                  />
                                  <div>
                                      {lastUpdate && (
                                          <Title>
                                              <div>
                                                  <span>oldest: </span>
                                                  <b>{new Date(+oldUpdate).toLocaleString()}</b>
                                              </div>
                                              <div>
                                                  <span>lastest: </span>
                                                  <b>{new Date(+lastUpdate).toLocaleString()}</b>
                                              </div>
                                          </Title>
                                      )}
                                  </div>
                              </>
                          )
                      })
                    : null}
            </Container>
        </StatsContainer>
    )
}

function Dashboard() {
    const [state] = useContext(GlobalContext)
    const [productAvailableList, setProductAvailableList] = useState([])
    const [allStats, setAllStats] = useState([])

    const recursive = async (list) => {
        const promise = list.map(async (model) => fetchStatsProduct(model.name))
        const res = await Promise.all(promise)
        return res
    }

    useEffect(() => {
        setProductAvailableList(state.products)
    }, [state.products.length])

    useEffect(() => {
        if (productAvailableList.length) {
            const list = [...productAvailableList]
            const used = list.splice(0, 1)
            recursive(used).then((res) => {
                setProductAvailableList(list)
                setAllStats((prev) => [...prev, { name: used[0].name, rangeIndex: used[0].rangeIndexDB, data: res[0] }])
            })
        }
    }, [productAvailableList])

    return (
        <div className='Tab'>
            <Grid container spacing={3}>
                {allStats.map(
                    (product, index) =>
                        product && (
                            <Grid item xs={4} key={'card-product-' + index}>
                                <StyledPaper variant='outlined'>
                                    <StyledTypography>{product.name}</StyledTypography>
                                    <Avatar>{product.rangeIndex}</Avatar>
                                    <Stats stats={product.data} name={product.name} />
                                </StyledPaper>
                            </Grid>
                        )
                )}
                {productAvailableList.length > 0 ? (
                    <Grid item xs={4}>
                        <Paper variant='outlined'>
                            <Loader />
                            <StyledTypography>{productAvailableList.length} more to load</StyledTypography>
                            <br />
                        </Paper>
                    </Grid>
                ) : null}
            </Grid>
        </div>
    )
}

export default Dashboard
