import { useEffect, useState, useContext } from 'react'
import queryString from 'query-string'
import {
    postAllCalculationAsync,
    postCalculationLock,
    getCalculationLock,
} from '../../Assets/Interfaces/CalculationInterface'
import { GetAllCandidatesSizesByName, GetAllCandidatesByNameAndSize } from '../../Assets/Interfaces/CandidatesInterface'
import { useNavigate, useLocation } from 'react-router-dom'
import { GlobalContext } from './../../store/index'
import { sortByUppercaseName } from './../../Assets/Helpers'

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])
    return debouncedValue
}

const initialCalcutlation = {
    seerMT: false,
    seerLT: false,
    seprMT: false,
    seprHT: false,
    scopLTa: false,
    scopLTw: false,
    scopLTc: false,
    scopITa: false,
    scopITw: false,
    scopITc: false,
    scopMTa: false,
    scopMTw: false,
    scopMTc: false,
    scopHTa: false,
    scopHTw: false,
    scopHTc: false,
    scopbLTa: false,
    scopbLTw: false,
    scopbLTc: false,
    scopbITa: false,
    scopbITw: false,
    scopbITc: false,
    scopbMTa: false,
    scopbMTw: false,
    scopbMTc: false,
    scopbHTa: false,
    scopbHTw: false,
    scopbHTc: false,
}

const parsedFilter = queryString.parse(location.search)

const initialFilter = {
    ...{
        size: '',
        option: '',
        status: '',
        optionStrict: true,
        calcType: '',
        calcTypeValue: '',
    },
    ...parsedFilter,
    optionStrict: String(parsedFilter.optionStrict).toLowerCase() === 'true',
}
const useCalculation = (productName) => {
    const [state] = useContext(GlobalContext)
    const [candidates, setCandidates] = useState([])
    const [filter, setFilter] = useState(initialFilter)
    const [status, setStatus] = useState(null)
    const [candidatesSelected, setCandidatesSelected] = useState([])
    const [candidatesFiltred, setCandidatesFiltred] = useState(null)
    const [selectAll, setSelectAll] = useState(false)
    const [calculationSelected, setCalculationSelected] = useState(initialCalcutlation)
    const [update, setUpdate] = useState({
        id: null,
        status: null,
        calcType: null,
        requestId: null,
        model: null,
    })

    const [candidatesLocked, setCandidatesLocked] = useState(null)
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const debouncedUpdate = useDebounce(update, 1000)

    const isSelectedCheckbox = (id = -1) => (candidatesSelected.findIndex((el) => el.id === +id) !== -1 ? true : false)

    const resetFilter = () => {
        location.search = ''
        navigate(location)
        setFilter(initialFilter)
        setCandidatesFiltred([])
    }

    const toggleSelectAll = () => {
        if (!selectAll) setCandidatesSelected(candidatesFiltred.length ? candidatesFiltred : candidates)
        else setCandidatesSelected([])

        setSelectAll((prev) => !prev)
    }

    const toggleCheckboxSelection = ({ target: { checked } }, candidateId) => {
        const candidateSelected = candidates.find(({ id }) => +id === +candidateId)

        if (checked) setCandidatesSelected((prev) => [...prev, candidateSelected])
        else setCandidatesSelected((prev) => prev.filter(({ id }) => +id !== +candidateSelected.id))
    }

    const filterCandidatesBy = (noddle, list, type, option) => {
        switch (type) {
            case 'size': {
                return list.filter((el) =>
                    el.Parameters.find((el) => el.Description === 'Size' && el.Values[0].Value.includes(noddle))
                )
            }
            case 'option': {
                return list.filter((el) =>
                    el.Parameters.find((el) => el.Description === noddle && el.Values[0].Value === option)
                )
            }

            case 'status': {
                if (noddle === 'empty') {
                    return list.filter(
                        ({ Parameters }) => !Parameters[0].Values[0].Status.find((el) => el.CalcType?.trim() === option)
                    )
                }
                const filtered = list.filter(({ Parameters }) => {
                    return (
                        Parameters[0].Values[0].Status.find((el) => el.CalcType.trim() === option)?.CalcStatus.split(
                            ' '
                        )[0] ===
                        noddle.charAt(0).toUpperCase() + noddle.slice(1)
                    )
                })
                return filtered
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let candidateList = [...candidates]
        let candidateMatch = []

        if (filter.size.length) {
            const sizeNoddle = filter.size.trim().split(' ')
            sizeNoddle.forEach((noddle) => {
                if (noddle) {
                    candidateMatch = [...candidateMatch, ...filterCandidatesBy(noddle, candidateList, 'size')]
                }
            })
            candidateList = [...candidateMatch]
        }

        if (filter.option.length) {
            candidateMatch = []
            const optionNoddle = filter.option.trim().split(' ')
            optionNoddle.forEach((noddle) => {
                if (noddle) {
                    let option = noddle.trim().split(':')
                    if (!option[1]) option[1] = 'TRUE'
                    if (filter.optionStrict) {
                        candidateMatch = [
                            ...filterCandidatesBy(
                                option[0],
                                candidateMatch.length ? candidateMatch : candidateList,
                                'option',
                                option[1].toUpperCase()
                            ),
                        ]
                    } else {
                        candidateMatch = [
                            ...candidateMatch,
                            ...filterCandidatesBy(option[0], candidateList, 'option', option[1]),
                        ]
                    }
                }
            })
            candidateList = [...candidateMatch]
        }

        if (filter.calcType && filter.calcTypeValue) {
            candidateMatch = [...filterCandidatesBy(filter.calcTypeValue, candidateList, 'status', filter.calcType)]
            candidateList = [...candidateMatch]
        }
        setCandidatesFiltred(candidateList)
        location.search = queryString.stringify(filter)
        navigate(location)
    }

    const handleChange = ({ target: { value, name } }) => {
        setFilter((prev) => ({ ...prev, [name]: value }))
    }

    const toggleCheckbox = ({ target: { name, checked } }) => {
        setCalculationSelected((prev) => ({ ...prev, [name]: checked }))
    }

    const toggleAllCheckbox = (calculationList, { target: { checked } }) => {
        const updatedCalculationSelected = Object.assign({}, calculationSelected)
        calculationList.forEach((key) => (updatedCalculationSelected[key] = checked))
        setCalculationSelected(updatedCalculationSelected)
    }

    const simpleCandidate = (candidate, calcTypeSelected) => {
        const Parameters = candidate.Parameters.map((el) => ({ Name: el.Name, Value: el.Values[0].Value })).sort(
            sortByUppercaseName
        )
        return { Parameters, calcType: calcTypeSelected }
    }

    const checkLockedCandidate = (candidate, calcType) => {
        if (candidatesLocked && !candidatesLocked.length) return false
        const candidatesLockedBycalcType = candidatesLocked?.filter((el) => el.calcType[calcType])
        const checked = candidatesLockedBycalcType?.filter(
            (el) => JSON.stringify(simpleCandidate(candidate).Parameters) === JSON.stringify(el.Parameters)
        )
        return checked?.length > 0
    }

    const saveCandidatesLocked = (e) => {
        e.preventDefault()

        const calculReduced = Object.entries(calculationSelected).reduce((acc, curr) => {
            if (curr[1]) acc[curr[0]] = curr[1]
            return acc
        }, {})

        const stringified = candidatesSelected.map((el) => JSON.stringify(simpleCandidate(el).Parameters))

        setCandidatesLocked((prev) => {
            return [
                ...prev.filter(({ Parameters }) => !stringified.includes(JSON.stringify(Parameters))),
                ...candidatesSelected.map((candidate) => simpleCandidate(candidate, calculReduced)),
            ]
        })
    }

    const deleteCandidatesLocked = (e) => {
        e.preventDefault()
        setCandidatesLocked([])
    }

    const fetchPostAllCalculations = async (e) => {
        e.preventDefault()

        const calculReduced = Object.entries(calculationSelected).reduce((acc, curr) => {
            if (curr[1]) acc[curr[0]] = curr[1]
            return acc
        }, {})

        const calculationRun = state.ui.calculationRun

        if (candidatesSelected.length) {
            const totalCandidates = candidatesSelected.length
            setLoader(true)
            for (let i = 0; i < totalCandidates; i += calculationRun) {
                const candidates = candidatesSelected.slice(i, i + calculationRun)
                try {
                    await postAllCalculationAsync(candidates, {
                        ...calculReduced,
                        model: productName,
                        user: state.user.name,
                    })
                } catch (error) {
                    console.error(error)
                }
            }
            setLoader(false)
        }
    }

    const fetchCandidatesByName = async (product) => {
        const sizes = await GetAllCandidatesSizesByName(product)
        let allCandidateValue = []
        for (var i = 0; i < sizes.length; i++) {
            const allCandidates = await GetAllCandidatesByNameAndSize(product, sizes[i].Value)
            allCandidateValue = allCandidateValue.concat(allCandidates)
        }
        return allCandidateValue
    }

    useEffect(() => {
        if (state.socket) {
            state.socket.on('calculation', (dataSocket) => {
                //setUpdate(dataSocket)
            })
        }
    }, [state.socket])

    useEffect(() => {
        if (candidates) {
            const allStatus = []
            candidates.map((el) =>
                el.Parameters[0].Values[0].Status.map((el) => {
                    allStatus.push(el?.CalcType?.trim())
                })
            )
            setStatus([...new Set(allStatus.filter(Boolean))])
        }
    }, [candidates])

    useEffect(() => {
        if (productName) {
            fetchCandidatesByName(productName).then((res) => {
                setCandidates(res)
                setCandidatesFiltred(res)
            })
            getCalculationLock(productName).then((res) => setCandidatesLocked(res))
        }
    }, [productName])

    useEffect(() => {
        if (debouncedUpdate.id && debouncedUpdate.model === productName) {
            fetchCandidatesByName(productName).then((res) => setCandidates(res))
        }
    }, [debouncedUpdate, debouncedUpdate.id])

    useEffect(() => {
        if (candidatesLocked) {
            postCalculationLock(
                candidatesLocked.map(({ Parameters, calcType }) => ({ Parameters, calcType })),
                productName
            )
        }
    }, [candidatesLocked])

    const toggleOptionStrict = () => {
        setFilter((prev) => ({ ...prev, optionStrict: !prev.optionStrict }))
    }

    return {
        candidates: candidatesFiltred,
        fetchPostAllCalculations,
        toggleCheckbox,
        toggleAllCheckbox,
        toggleCheckboxSelection,
        handleChange,
        handleSubmit,
        toggleOptionStrict,
        filter,
        isSelectedCheckbox,
        toggleSelectAll,
        selectAll,
        resetFilter,
        history,
        calculationSelected,
        status,
        candidatesSelected,
        saveCandidatesLocked,
        deleteCandidatesLocked,
        checkLockedCandidate,
        loader,
    }
}

export default useCalculation
